import axios from "axios";
import { useState } from "react";
import './Home.admin.css'
import Offer1Admin from "./Offer1.admin";
import CategorysAdmin from "./Categorye.admin";
import Product1Admin from "./Product1.admin";
import NewProductsAdmin from "./NewProducts.admin";
import PackagesAdmin from "./Packages.admin";
import MostCommonAdmin from "./MostCommon.admin";
import ShoppBrandAdmin from "./ShoppBrand.admin";
import { useSelector } from "react-redux";
import img from '../../files/HomeGuest/ضصثضصيسش.jpg'
import { RootState } from "../../redux/store";


export interface Product {
    _id?: string;
    category?: {
        en: string;
        ar: string;
    };
    brand?: {
        en: string;
        ar: string;
    };
    file?: string[];
    introVideo?: string;
    fileIllustrativePictures?: string[];
    product?: {
        en: string;
        ar: string;
    };
    information?: {
        en: string;
        ar: string;
    };
    sold: number;
    price?: number;
    number?: number;
    maxNumber?: number;
    newPrice?: number;
    priceOfferForTwoProducts?: number;
    offerForTwoProducts?: boolean;
    discount?: number;
    favorite?: string[];
    ForYouMyLady?: boolean;
    offers?: boolean;
    createdAt?: Date;
    disable?: boolean;
    colors?: string[];
    target?: string;
    statusCategory?: string;
    classification?: string;
    categorys?: Record<string, string>;
    remind?: string[];
}
export interface ProductSearch {
    error?: boolean;
    total?: number;
    page?: number;
    limit?: number;
    movies?: {
        _id?: string;
        category?: {
            en: string;
            ar: string;
        };
        brand?: {
            en: string;
            ar: string;
        };
        file?: string[];
        product?: {
            en: string;
            ar: string;
        };
        information?: {
            en: string;
            ar: string;
        };
        sold: number;
        price?: number;
        number?: number;
        newPrice?: number;
        discount?: number;
        favorite?: string[];
        offers?: boolean;
        createdAt?: Date;
    }[];
}

export interface Category {
    _id: string;
    name: {
        en: string;
        ar: string;
    };
    brand: [{
        en: string;
        ar: string;
    }];
    Categorys?: {
        name: {
            en: string,
            ar: string,
        },
        Categories: {
            en: string,
            ar: string,
        }[]
    }[];
    file?: string[];
}
export interface Brand {
    _id: string;
    name: {
        en: string;
        ar: string;
        he: string;
    }
}
export interface Offers {
    _id: string;
    nameOffer: {
        en: string;
        ar: string;
        he: string;
    };
    file?: string[];
}

export interface UsersProps {
    _id?: string;
    email?: string;
    userName?: string;
    invitationName?: string;
    theOneWhoInvitedHim?: string;
    pointsInvite?: number;
    phone?: number;
    active?: boolean;
    role?: boolean;
    favorite?: string[];
    cart?: {
        itemId?: string;
        quantity?: number;
        color?: string;
    }[];
    address?: {
        country?: string,
        neighborhood?: string,
        street?: string,
        buildingNumber?: string | number,
        floor?: number,
        apartmentNo?: number,
    },
}

export interface ChatProps {
    createdAt?: Date;
    members?: string[]
    updatedAt?: Date;
    _id?: string
}

export interface OrdersProps {
    userId: string;
    phone: number;
    totalPrice: number;
    paymentMethod: string;
    dataItems: {
        itemId: string;
        product: {
            ar: string;
            en: string;
            he: string
        };
        price: string;
        newPrice: string;
        offers: boolean;
        quantity: number;
    }[];
    createdAt: Date;
}

const HomeAdmin: React.FC = () => {
    const userData = useSelector((state: RootState) => state.userData)

    const [id, setId] = useState<string>('')

    const BtnDelete = async () => {
        await axios.delete(`${process.env.REACT_APP_URL_API}/API/Offers/DeleteOffer/${id}`,
            {
                headers: {
                    Authorization: "Bearer " + userData.user.token,
                }
            }
        )
            .then((doc) => console.log(doc))
            .catch((err) => console.log(err))
    }
    return (<>
        <div>
            <div>
                <div>
                    <Offer1Admin />
                </div>
                <div>
                    <CategorysAdmin />
                </div>
                <div>
                    <Product1Admin />
                </div>
                <div>
                    <NewProductsAdmin />
                </div>
                {/* <div>
                    <div
                        style={{
                            minHeight: "100vh", width: "100%", backgroundImage: `url(${img})`, backgroundRepeat: "no-repeat",
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            display: "flex", justifyContent: "center", alignItems: "center"
                        }}
                    >
                        <Button bsPrefix="BtnBNB">تواصل معنا على الواتس أب</Button>
                    </div>
                </div> */}
                <div>
                    <PackagesAdmin />
                </div>
                <div>
                    <MostCommonAdmin />
                </div>
                <div>
                    <ShoppBrandAdmin />
                </div>

            </div>
        </div>
    </>)
}

export default HomeAdmin;