import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import { Loading } from "../../../components/laoding/laoding";
import { ThemeColor } from "../../../theme";
import { useTranslation } from "react-i18next";
import InfoProduct from "./InfoProduct";
import { motion } from "framer-motion";
import { ChatContext } from "../../../components/context/ChatContext";
import { Product } from "../../../admin/homeAdmin/HomeAdmin";
import YouMayAlsoLikeGuest from "../YouMayAlsoLikeGuest";
import Langu from "../../../components/languge/languge";
import VideoAndPhoto from "../../../client/components/ProductDetailsClient/VideoAndPhoto";
import { Link } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import MessageProductGuest from "./MessageProductGuest";
import ProductsBrandGuest from "./ProductsBrandGuest";
import ProductsCategoryGuest from "./ProductsCategoryGuest";



const ProductDetails = () => {
    const { t } = useTranslation()
    const params = useParams()
    const [dataProduct, setDataProduct] = useState<Product>()
    const [photoActive, setPhotoActive] = useState<string>(dataProduct?.file?.[0]!)
    const [showMessage, setShowMessage] = useState(false);
    const [hasScrolled, setHasScrolled] = useState(false);

    const context = useContext(ChatContext)
    const { isMobile } = context!
    useEffect(() => {
        const handleScroll = () => {
            const scrollY = window.scrollY;
            if (scrollY >= 1000 && !hasScrolled) {
                setHasScrolled(true);
                setShowMessage(true);
            }
            else if (scrollY < 1000) {
                setHasScrolled(false);
                setShowMessage(false);
            }
        };
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [hasScrolled]);
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_URL_API}/API/Products/GetProduct/${params.id}`)
            .then((doc) => setDataProduct(doc.data))
            .catch((err) => console.log(err))
        window.scrollTo(0, 0)
    }, [params.id])

    useEffect(() => {
        setPhotoActive(dataProduct?.file?.[0]!)
    }, [dataProduct])


    return (<>
        {dataProduct ?
            <div style={{ display: 'flex', justifyContent: "center" }}>
                <div style={isMobile ? { display: "flex", justifyContent: "center", margin: "10vh 0", flexDirection: "column" } : { display: "flex", justifyContent: "center", margin: "0px 0 10vh 0", flexDirection: "column", width: "100%", alignItems: "center" }}>
                    <div style={{ margin: "50px 0 20px 0", display: "flex", gap: 10, width: "100%", maxWidth: 1660 }}>
                        <Link to={'/guest/homePage'} style={{ fontWeight: 600, textDecoration: "none", color: "initial" }} >{t('Home.home')}</Link> /
                        <Link to={`/guest/category/${dataProduct.category?.en}`} style={{ fontWeight: 600, color: "initial", textDecoration: "none" }} ><Langu {...dataProduct.category!} /></Link> /
                        <Link to={''} style={{ fontWeight: 600, color: "gray", textDecoration: "none" }} ><Langu {...dataProduct.product!} /></Link>
                    </div>
                    <div style={isMobile ? { width: "100%", maxWidth: 1660, display: "flex", gap: 20, flexDirection: "column" } : { width: "100%", maxWidth: 1660, display: "flex", gap: 20 }}>
                        <div style={isMobile ? { maxWidth: 600, display: "flex", flexDirection: "column" } : { maxWidth: 600, display: "flex" }}>
                            <div style={isMobile ? { display: "flex", justifyContent: "center" } : { display: "flex", flexDirection: "column" }}>
                                {dataProduct?.file?.[0] &&
                                    <img alt="وصف الصورة" draggable={false}
                                        onClick={() => isMobile ? setPhotoActive(dataProduct?.file?.[0] ?? '') : undefined}
                                        onMouseEnter={() => {
                                            if (!isMobile) setPhotoActive(dataProduct?.file?.[0] ?? '');
                                        }}
                                        onMouseLeave={() => {
                                            if (!isMobile) setPhotoActive(dataProduct?.file?.[0] ?? '');
                                        }}
                                        style={{ width: 100, height: 100, border: photoActive === dataProduct?.file?.[0] ? `2px ${ThemeColor.bgColor(1)} solid` : "none", padding: 3 }}
                                        src={`${process.env.REACT_APP_URL_API}/API/files/Products/${dataProduct?.file?.[0] ?? ""}`} />
                                }
                                {dataProduct?.file?.[1] &&
                                    <img alt="وصف الصورة" draggable={false}
                                        onClick={() => isMobile ? setPhotoActive(dataProduct?.file?.[1] ?? '') : undefined}
                                        onMouseEnter={() => {
                                            if (!isMobile) setPhotoActive(dataProduct?.file?.[1] ?? '');
                                        }}
                                        onMouseLeave={() => {
                                            if (!isMobile) setPhotoActive(dataProduct?.file?.[0] ?? '');
                                        }}
                                        style={{ width: 100, height: 100, border: photoActive === dataProduct?.file?.[1] ? `2px ${ThemeColor.bgColor(1)} solid` : "none", padding: 3 }}
                                        src={`${process.env.REACT_APP_URL_API}/API/files/Products/${dataProduct?.file?.[1] ?? ""}`} />
                                }
                                {dataProduct?.file?.[2] &&
                                    <img alt="وصف الصورة" draggable={false}
                                        onClick={() => isMobile ? setPhotoActive(dataProduct?.file?.[2] ?? '') : undefined}
                                        onMouseEnter={() => {
                                            if (!isMobile) setPhotoActive(dataProduct?.file?.[2] ?? '');
                                        }}
                                        onMouseLeave={() => {
                                            if (!isMobile) setPhotoActive(dataProduct?.file?.[0] ?? '');
                                        }}
                                        style={{ width: 100, height: 100, border: photoActive === dataProduct?.file?.[2] ? `2px ${ThemeColor.bgColor(1)} solid` : "none", padding: 3 }}
                                        src={`${process.env.REACT_APP_URL_API}/API/files/Products/${dataProduct?.file?.[2] ?? ""}`} />
                                }
                            </div>
                            <div style={{ position: "relative" }}>
                                <motion.img
                                    key={photoActive}
                                    draggable={false}
                                    initial={{ opacity: 0, scale: 0.9 }} 
                                    animate={{ opacity: 1, scale: 1 }} 
                                    exit={{ opacity: 0, scale: 0.9 }} 
                                    transition={{ duration: 0.5 }} 
                                    style={{
                                        width: isMobile ? "100%" : 498,
                                        height: isMobile ? "100%" : 490,
                                        animation: " 1s",
                                        transition: "ease 1s",
                                    }}
                                    src={`${process.env.REACT_APP_URL_API}/API/files/Products/${photoActive ?? ""}`}
                                />
                                {
                                    dataProduct.offers &&
                                    <div style={{ position: "absolute", top: dataProduct.disable ? "90px" : "20px", left: "20px", backgroundColor: "red", borderRadius: "50%", padding: "10px", color: "white", width: "65px", height: "60px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        {dataProduct.discount! * -1} %
                                    </div>
                                }
                                {
                                    dataProduct.disable &&
                                    <div style={{ position: "absolute", top: "20px", left: "20px", backgroundColor: "black", borderRadius: "50%", padding: "10px", color: "white", width: "65px", height: "65px", display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center" }}>
                                        {t("Market.Sold out")}
                                    </div>
                                }
                                <div style={{ marginTop: 30 }}>
                                    <Langu {...dataProduct?.information!} />
                                </div>
                            </div>
                        </div>
                        <div style={{ maxWidth: 600, width: "100%", minWidth: 600 }}>
                            <InfoProduct data={dataProduct} />
                        </div>
                        <div style={{ maxWidth: 460, width: "100%" }}>
                            <div style={{ textAlign: 'center', fontWeight: 700, fontSize: 20 }}>{t("Home.You may also like")}</div>
                            <YouMayAlsoLikeGuest />
                        </div>
                    </div>
                    <div style={{ width: "100%", maxWidth: 1660, display: "flex", justifyContent: "center" }}>
                        <VideoAndPhoto />
                    </div>
                    <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                        <ProductsCategoryGuest category={dataProduct?.category?.en!} />
                    </div>
                    <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                        <ProductsBrandGuest brand={dataProduct?.brand?.en!} />
                    </div>
                </div>
            </div>
            :
            <Loading />
        }
        <CSSTransition
            in={showMessage}
            timeout={300}
            classNames="footer-animation"
            unmountOnExit
            appear
        >
            <MessageProductGuest data={dataProduct!} />
        </CSSTransition>
    </>)
}

export default ProductDetails;