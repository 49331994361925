import axios from "axios";
import { useEffect, useState } from "react";
import { Button, Form, InputGroup, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { RootState } from "../../../../redux/store";
import { useSelector } from "react-redux";
import { IoSearchSharp } from "react-icons/io5";


export interface OrderProps {
    data: any;
    _id?: string
    userId?: string;
    userName?: string;
    phone?: number;
    totalPrice?: number;
    paymentMethod?: string;
    dataItems?: {
        itemId?: string;
        product?: {
            ar: string;
            en: string;
            he: string
        };
        price?: number;
        newPrice?: number;
        offers?: boolean;
        quantity?: number;
        color?: string;
        offerForTwoProducts?: boolean;
        priceOfferForTwoProducts?: number;
    }[];
    createdAt?: Date;
    address?: {
        country?: string;
        neighborhood?: string;
        street?: string;
        buildingNumber?: string | number;
        floor?: number;
        apartmentNo?: number;
    },
    status?: string;
    cancelOrder?: boolean;
    delivery: number;
    useWallet: boolean;
    valueWallet: number;
}


const GetOrdersAdmin: React.FC = () => {
    const userData = useSelector((state: RootState) => state.userData);
    const { t } = useTranslation()
    const nav = useNavigate()
    const [dataOrders, setDataOrders] = useState<OrderProps[]>()
    const [search, setSearch] = useState<string>()

    const getSearchData = async () => {
        await axios.post(`${process.env.REACT_APP_URL_API}/API/Orders/PostSearchOrders`, { search },
            {
                headers: {
                    Authorization: "Bearer " + userData.user.token,
                }
            }
        )
            .then((doc) => setDataOrders(doc.data.movies))
            .catch((err) => console.log(err))
    }

    useEffect(() => {
        const debounce = setTimeout(() => {
            getSearchData()
        }, 800)
        return () => clearTimeout(debounce)
    }, [search])


    return (<>
        <div style={{ backgroundColor: "gray", minHeight: "20vh", width: '100%' }}>
            <div className="nameOffer" style={{ top: "calc(10vh + 60px)" }}>{t("Home.Orders")}</div>
        </div>
        <div style={{ width: "100%", margin: "30px 0" }}>
            <div style={{ display: "flex", justifyContent: "center" }}>
                <Form className="d-flex search-bar" style={{ width: "80%", margin: "0", display: 'flex', maxWidth: 800 }}>
                    <InputGroup>
                        <InputGroup.Text style={{ backgroundColor: "white", borderLeft: "none", borderRadius: "0" }}>
                            <IoSearchSharp style={{ color: "#6f24d7", fontSize: "20px" }} />
                        </InputGroup.Text>
                        <Form.Control
                            type="search"
                            placeholder={t("Hedar.Search")}
                            aria-label="Search"
                            value={search}
                            style={{ borderRadius: "0", borderRight: "none", margin: "0", padding: "0" }}
                            onChange={(e) => setSearch(e.target.value)}
                        />
                    </InputGroup>
                </Form>
            </div>
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
            <div style={{ maxWidth: "1440px", width: "100%", padding: "0 40px" }}>
                <div>
                    <Table striped>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>{t("Home.name")}</th>
                                <th>{t("Market.Total price")}</th>
                                <th>{t("Home.phone")}</th>
                                <th>{t("Market.PayPage.Payment methods")}</th>
                                <th >{t("Market.status")}</th>
                                <th style={{ width: "15%" }}></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                dataOrders?.sort((a, b) => new Date(b.createdAt!).getTime() - new Date(a.createdAt!).getTime()).map((order, index) =>
                                    <tr key={order._id}>
                                        <td>{order._id}</td>
                                        <td>{order.userName}</td>
                                        <td>{order.totalPrice} ₪</td>
                                        <td>{order.phone}</td>
                                        <td>{order.paymentMethod}</td>
                                        <td>{t(`Market.PayPage.${order.status}`)}</td>
                                        <td ><Button bsPrefix="BtnBNB" onClick={() => nav(`/admin/market/orders/getOrder/${order._id}`)}>{t("Market.Order details")}</Button></td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </Table>
                </div>
            </div>
        </div>
    </>)
}
export default GetOrdersAdmin;