import { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { LoadingBtn } from "../../../components/laoding/laoding";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import GetOffersAndDelete from "./GetOffersAndDelete";
import InvitationBonus from "./InvitationBonus";
import Delivery from "./Delivery";


const CreateOffer: React.FC = () => {
    const userData = useSelector((state: RootState) => state.userData)
    const { t } = useTranslation()
    const [statusBtnCreate, setStatusBtnCreate] = useState<any>(t("Market.Create Offer"))
    const [disabledBtnCreate, setDisabledBtnCreate] = useState<boolean>(false)
    const [photo, setPhoto] = useState<File[]>([])
    const [errInput, setErrInput] = useState<string>()

    useEffect(() => {
        setErrInput('')
    }, [photo])

    const handelPhoto = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        if (files && files.length > 0) {
            setPhoto(Array.from(files))
        }
    }

    const BtnCreate = async () => {
        setStatusBtnCreate(<LoadingBtn />)
        setDisabledBtnCreate(true)
        if (photo.length == 0) {
            setDisabledBtnCreate(false)
            setStatusBtnCreate(t("Market.Create Offer"))
            setErrInput(t("Market.Please enter photo"))
            return;
        }

        const selectedFiles = []

        for (let i = 0; i < photo.length; i++) {
            selectedFiles.push(photo[i]);
        }
        const updatedFormData = new FormData();
        for (let i = 0; i < selectedFiles.length; i++) {
            updatedFormData.append("file", selectedFiles[i]);
        }

        await axios.post(`${process.env.REACT_APP_URL_API}/API/Offers/PostOffer`, updatedFormData,
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: "Bearer " + userData.user.token,
                }
            }
        )
            .then((doc) => console.log(doc))
            .catch(err => console.log(err))
            .finally(() => {
                setStatusBtnCreate(t("Market.Create Offer"))
                setDisabledBtnCreate(false)
            })
    }

    return (<>
        <div style={{ display: "flex", justifyContent: "center" }}>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", maxWidth: "1440px", width: "100%", boxShadow: "rgb(214, 214, 214) 5px 6px 10px", padding: "30px", borderRadius: "10px", border: "solid 1px #d6d6d6", margin: "20px 10% 0 10%" }}>
                <h1 style={{ width: "100%", borderBottom: "1px solid rgb(214, 214, 214)", textAlign: "center", paddingBottom: "20px" }}>{t("Market.Create Offer")}</h1>
                <div style={{ width: "100%", maxWidth: "700px" }}>
                    <Form.Group className="mb-3" controlId="exampleForm.Photos">
                        <Form.Label>{t("Market.Photo")} :</Form.Label>
                        <Form.Control type="file" onChange={handelPhoto} multiple />
                    </Form.Group>
                </div>

                <div>
                    <div style={{ color: "red" }}>{errInput}</div>
                    <Button bsPrefix="BtnBNB" onClick={BtnCreate} disabled={disabledBtnCreate}>{statusBtnCreate}</Button>
                </div>

                <GetOffersAndDelete refreshData={disabledBtnCreate} />
            </div>
        </div>
        <div>
            <InvitationBonus />
        </div>
        <div style={{ marginBottom: 50 }}>
            <Delivery />
        </div>
    </>)
}

export default CreateOffer;