import React from 'react';
import './App.css';
import { Navigate, Route, Routes } from 'react-router';
import Home from './guest/homeGuest/HomeGuest';
import 'bootstrap/dist/css/bootstrap.min.css';
import HomeAdmin from './admin/homeAdmin/HomeAdmin';
import OutletGuest from './guest/outlet/OutletGuest';
import OutletAdmin from './admin/outlet/OutletAdmin';
import CreateCategory from './admin/market/createCategory/CreateCategory';
import CreateProduct from './admin/market/createProduct/CreateProduct';
import CreateOffer from './admin/market/createOffer/CreateOffer';
import CreateUser from './users/createuser/CreateUser';
import CategoryGuest from './guest/market/category/Category.Guest';
import RefreshPage from './components/refreshPage/ReftrshPage';
import OutletClient from './client/outlet/OutletClient';
import HomeClient from './client/homeClient/HomeClient';
import { ValiedTokenAdmin, ValiedTokenClient } from './users/valiedToken/ValiedToken';
import CategoryClient from './client/market/category/Category.client';
import UpdataProduct from './admin/market/updateProduct/UpdataProduct';
import Favorites from './client/market/Favorites/Favorites';
import CategoryAdmin from './admin/market/category/Category.admin';
import PayPage from './client/market/payPage/PayPage';
import CompleteOrder from './client/market/payPage/CompleteOrder';
import GetOrdersAdmin from './admin/market/orders/getOrders/GetOrders.admin';
import Dashboard from './admin/Dashboard/Dashboard';
import UserId from './admin/Dashboard/userId.admin';
import GetOrderAdmin from './admin/market/orders/getOrder/GetOrder.admin';
import GetPackagesGuest from './guest/market/getPackages.guest/GetPackages.guest';
import GetNewProductsGuest from './guest/market/getNewProducts.guest/GetNewProducts.guest';
import GetProduct1Guest from './guest/market/getProduct1.guest/GetProduct1.guest';
import GetMostCommonGuest from './guest/market/getMostCommon.guest/GetMostCommon.guest';
import SearchPageGuest from './guest/market/searchPage/SearchPageGuest';
import GetMostCommonClient from './client/market/getMostCommon.client/GetMostCommon.client';
import GetProduct1Client from './client/market/getProduct1.client/GetProduct1Client';
import GetNewProductsClient from './client/market/getNewProductsClient/GetNewProductsClient';
import GetPackagesClient from './client/market/getPackagesClient/GetPackagesClient';
import SearchPageClient from './client/market/searchPage/SearchPageClient';
import MyOrders from './client/MyAccount/MyOrders';
import OrderDetalsClient from './client/MyAccount/OrderDetals.client';
import Invoice from './client/market/payPage/Invoice';
import AccountDetals from './client/MyAccount/AccountDetals';
import ChangeEmail from './client/MyAccount/ChangeEmail';
import Chat from './components/Chat/Chat';
import AccountDetalsAdmin from './admin/myAccount/AccountDetals';
import GetPackagesAdmin from './admin/market/getAlLProduct/getPackages.admin/GetPackagesAdmin';
import GetNewProductsAdmin from './admin/market/getAlLProduct/getNewProducts.admin/GetNewProductsAdmin';
import GetProduct1Admin from './admin/market/getAlLProduct/getProduct1.admin/GetProduct1Admin';
import GetMostCommonAdmin from './admin/market/getAlLProduct/getMostCommon.admin/getMostCommon.admin';
import ForgotPasswordPage from './users/forgotPassword/ForgotPasswordPage';
import ProductDetailsClient from './client/components/ProductDetailsClient/ProductDetailsClient';
import ProductDetails from './guest/component/ProductDetails/ProductDetails';
import UpDateCategory from './admin/market/category/UpDateCategory';
import GetForYouMyLadyClient from './client/market/getForYouMyLady.client/GetForYouMyLady.client';
import GetShoppBrandClient from './client/market/getShoppBrand.client/GetShoppBrand.client';
import GetMostLikedClient from './client/market/getMostLiked.client/GetMostLikedClient';
import GetForYouMyLadyGuest from './guest/market/GetForYouMyLady.guest/GetForYouMyLadyGuest';
import GetMostLikedGuest from './guest/market/getMostLiked.guest/GetMostLikedGuest';
import GetShoppBrandGuest from './guest/market/getShoppBrand.guest/GetShoppBrand.guest';
import Wallet from './client/MyAccount/Wallet';

const App: React.FC = () => {

  return (
    <>
      <Routes>
        <Route path='/' element={<Navigate to="/admin/homePage" replace />} />

        <Route path='/guest' element={<OutletGuest />} >
          <Route index path='/guest/homePage' element={<Home />} />
          <Route path='/guest/createUser/:token' element={<CreateUser />} />
          <Route path='/guest/category/:name' element={<CategoryGuest />} />
          <Route path='/guest/ForgotPasswordPage/:token' element={<ForgotPasswordPage />} />
          <Route path='/guest/market'>
            <Route path='/guest/market/getForYouMyLady' element={<GetForYouMyLadyGuest />} />
            <Route path='/guest/market/getPackages' element={<GetPackagesGuest />} />
            <Route path='/guest/market/getNewProducts' element={<GetNewProductsGuest />} />
            <Route path='/guest/market/getShoppBrandGuest/:brand' element={<GetShoppBrandGuest />} />
            <Route path='/guest/market/getProduct1' element={<GetProduct1Guest />} />
            <Route path='/guest/market/getMostLikedClient' element={<GetMostLikedGuest />} />
            <Route path='/guest/market/getMostCommon' element={<GetMostCommonGuest />} />
            <Route path='/guest/market/search/:product' element={<SearchPageGuest />} />
            <Route path='/guest/market/productDetails/:id' element={<ProductDetails />} />
          </Route>
        </Route>

        <Route element={<RefreshPage />}>
          <Route element={<ValiedTokenClient />}>
            <Route path='/client' element={<OutletClient />}>
              <Route index path='/client/homePage' element={<HomeClient />} />
              <Route path='/client/myAccount'>
                <Route path='/client/myAccount/myOrders' element={<MyOrders />} />
                <Route path='/client/myAccount/orderDetals/:id' element={<OrderDetalsClient />} />
                <Route path='/client/myAccount/AccountDetals/:id' element={<AccountDetals />} />
                <Route path='/client/myAccount/Wallet/:id' element={<Wallet />} />
                <Route path='/client/myAccount/changeEmail/:token/:id' element={<ChangeEmail />} />
              </Route>
              <Route path='/client/Chat' element={<Chat />} />
              <Route path='/client/market'>
                <Route path='/client/market/category/:name' element={<CategoryClient />} />
                <Route path='/client/market/Favorite/:id' element={<Favorites />} />
                <Route path='/client/market/productDetails/:id' element={<ProductDetailsClient />} />
                <Route path='/client/market/payPage' element={<PayPage />} />
                <Route path='/client/market/ompleteOrder' element={<CompleteOrder />} />
                <Route path='/client/market/invoice/:id' element={<Invoice />} />
                <Route path='/client/market/getMostCommon' element={<GetMostCommonClient />} />
                <Route path='/client/market/getForYouMyLady' element={<GetForYouMyLadyClient />} />
                <Route path='/client/market/getMostLikedClient' element={<GetMostLikedClient />} />
                <Route path='/client/market/getShoppBrandClient/:brand' element={<GetShoppBrandClient />} />
                <Route path='/client/market/getProduct1' element={<GetProduct1Client />} />
                <Route path='/client/market/getNewProducts' element={<GetNewProductsClient />} />
                <Route path='/client/market/getPackages' element={<GetPackagesClient />} />
                <Route path='/client/market/search/:product' element={<SearchPageClient />} />
              </Route>
            </Route>
          </Route>

          <Route element={<ValiedTokenAdmin />}>
            <Route path='/admin' element={<OutletAdmin />}>
              <Route path='/admin/chat' element={<Chat />} />

              <Route path='/admin/dashboard' element={<Dashboard />} />
              <Route path='/admin/dashboard/userId/:id' element={<UserId />} />
              <Route index path='/admin/homePage' element={<HomeAdmin />} />
              <Route path='/admin/myAccount'>
                <Route path='/admin/myAccount/AccountDetals/:id' element={<AccountDetalsAdmin />} />
                <Route path='/admin/myAccount/changeEmail/:token/:id' element={<ChangeEmail />} />
              </Route>
              <Route path='/admin/market'>
                <Route path='/admin/market/createCategory' element={<CreateCategory />} />
                <Route path='/admin/market/createProduct' element={<CreateProduct />} />
                <Route path='/admin/market/updateProduct/:id' element={<UpdataProduct />} />
                <Route path='/admin/market/createOffer' element={<CreateOffer />} />
                <Route path='/admin/market/category/:name' element={<CategoryAdmin />} />
                <Route path='/admin/market/updateCategory/:id' element={<UpDateCategory />} />
                <Route path='/admin/market/getMostCommon' element={<GetMostCommonAdmin />} />
                <Route path='/admin/market/getProduct1' element={<GetProduct1Admin />} />
                <Route path='/admin/market/getNewProducts' element={<GetNewProductsAdmin />} />
                <Route path='/admin/market/getPackages' element={<GetPackagesAdmin />} />
                <Route path='/admin/market/orders'>
                  <Route path='/admin/market/orders/getOrders' element={<GetOrdersAdmin />} />
                  <Route path='/admin/market/orders/getOrder/:id' element={<GetOrderAdmin />} />
                </Route>
              </Route>
              <Route path='/admin/users'>
                <Route path='/admin/users/getUsers' />
              </Route>
            </Route>
          </Route>
        </Route>

      </Routes >
    </>
  );
}

export default App;
