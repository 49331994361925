import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Product } from "../../../admin/homeAdmin/HomeAdmin";

import { FaFacebookF, FaInstagram, FaTiktok } from "react-icons/fa";
import { Button } from "react-bootstrap";


const VideoAndPhoto = () => {
    const params = useParams()
    const [dataProduct, setDataProduct] = useState<Product>()

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_URL_API}/API/Products/GetProduct/${params.id}`)
            .then((doc) => { setDataProduct(doc.data) })
            .catch((err) => console.log(err))
    }, [])

    return (<>
        <div>
            {
                dataProduct?.introVideo &&
                <div>
                    <video width="800" height="800" controls>
                        <source src={`${process.env.REACT_APP_URL_API}/API/files/Videos/${dataProduct.introVideo}`} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video >
                </div>
            }
            <div style={{ display: 'flex', flexDirection: "column", gap: 20 }}>
                {
                    dataProduct?.fileIllustrativePictures?.length! > 0 &&
                    dataProduct?.fileIllustrativePictures?.map((img, index) => (
                        <div key={index}>
                            <img style={{ width: 800, height: 800 }} src={`${process.env.REACT_APP_URL_API}/API/files/IllustrativePictures/${img ?? ""}`} />
                        </div>
                    ))
                }
            </div>
        </div>
    </>)
}

export default VideoAndPhoto;