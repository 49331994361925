import axios from "axios";
import { useEffect, useState } from "react";
import { Form, Button } from "react-bootstrap";
import { LoadingBtn } from "../../../components/laoding/laoding";
import CreateBrand from "./CreateBrand";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import CreateCategorys from "./CreateCategorys";


const CreateCategory: React.FC = () => {
    const userData = useSelector((state: RootState) => state.userData)
    const { t } = useTranslation()
    const [statusBtnCreate, setStatusBtnCreate] = useState<any>(t("Home.create Category"))
    const [disabledBtnCreate, setDisabledBtnCreate] = useState<boolean>(false)
    const [nameEN, setNameEN] = useState<string>('')
    const [nameAR, setNameAR] = useState<string>('')
    const [nameHE, setNameHE] = useState<string>('')
    const [photo, setPhoto] = useState<File[]>([])
    const [errInput, setErrInput] = useState<string>('')


    useEffect(() => {
        setErrInput('')
    }, [nameEN, nameAR, nameHE, photo])


    const handelPhoto = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        if (files && files.length > 0) {
            setPhoto(Array.from(files))
        }
    }


    const BtnCreate = async () => {
        setDisabledBtnCreate(true)
        setStatusBtnCreate(<LoadingBtn />)
        if (!nameEN || !nameAR || !nameHE) {
            setStatusBtnCreate(t("Home.create Category"))
            setDisabledBtnCreate(false)
            setErrInput(t("Market.Please enter data"))
            return;
        }
        if (photo.length == 0) {
            setStatusBtnCreate(t("Home.create Category"))
            setDisabledBtnCreate(false)
            setErrInput(t("Market.Please enter photo"))
            return;
        }

        const selectedFiles = []

        for (let i = 0; i < photo.length; i++) {
            selectedFiles.push(photo[i]);
        }
        const updatedFormData = new FormData();
        updatedFormData.append("nameEN", nameEN)
        updatedFormData.append("nameAR", nameAR)
        updatedFormData.append("nameHE", nameHE)

        for (let i = 0; i < selectedFiles.length; i++) {
            updatedFormData.append("file", selectedFiles[i]);
        }

        await axios.post(`${process.env.REACT_APP_URL_API}/API/Products/PostCategory`, updatedFormData,
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: "Bearer " + userData.user.token,
                }
            }
        )
            .then(doc => console.log(doc))
            .catch(err => console.log(err))
        setStatusBtnCreate(t("Home.create Category"))
        setDisabledBtnCreate(false)
    }

    return (<>
        <div style={{ display: "flex", justifyContent: "center" }}>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", maxWidth: "1440px", width: "100%", boxShadow: "rgb(214, 214, 214) 5px 6px 10px", padding: "30px", borderRadius: "10px", border: "solid 1px #d6d6d6", margin: "20px 10% 0 10%" }}>
                <h1 style={{ width: "100%", borderBottom: "1px solid rgb(214, 214, 214)", textAlign: "center", paddingBottom: "20px" }}>{t("Home.create Category")}</h1>
                <div style={{ width: "100%", maxWidth: "1000px" }}>
                    <Form.Label>{t("Market.Category name")} :</Form.Label>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1" style={{ display: "flex", gap: "10px" }}>
                        <Form.Control type="text" placeholder="English" autoFocus onChange={e => { setNameEN(e.target.value) }} />
                        <Form.Control type="text" placeholder="العربية" onChange={e => { setNameAR(e.target.value) }} />
                        <Form.Control type="text" placeholder="עִברִית" onChange={e => { setNameHE(e.target.value) }} />
                    </Form.Group>
                </div>
                <div style={{ width: "100%", maxWidth: "700px" }}>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>{t("Market.Photo")} :</Form.Label>
                        <Form.Control type="file" onChange={handelPhoto} multiple />
                    </Form.Group>
                </div>
                <div>
                    <div style={{ color: "red" }}>{errInput}</div>
                    <Button bsPrefix="BtnBNB" onClick={BtnCreate} disabled={disabledBtnCreate}>{statusBtnCreate}</Button>
                </div>
            </div>
        </div>
        <div>
            <CreateCategorys />
        </div>
        <div>
            <CreateBrand />
        </div>
    </>)
}

export default CreateCategory;