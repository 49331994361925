import axios from "axios";
import { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { OthersProps } from "../../../Interfaces";
import { LoadingBtn } from "../../../components/laoding/laoding";





const Delivery = () => {
    const { t } = useTranslation()
    const [dataOthers, setDataOthers] = useState<OthersProps>()
    const [westBank, setWestBank] = useState<number>()
    const [inside, setInside] = useState<number>()
    const [jordan, setJordan] = useState<number>()
    const [reload, setReload] = useState<boolean>(false)
    const [statusBtnCreate, setStatusBtnCreate] = useState<any>(t("Market.Add bonus"))
    const [statusBtnPut, setStatusBtnPut] = useState<any>(t("Home.Update"))
    const [disabledBtnCreate, setDisabledBtnCreate] = useState<boolean>(false)
    const [disabledBtnPut, setDisabledBtnPut] = useState<boolean>(false)

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_URL_API}/API/Others/GetOthers`)
            .then((doc) => setDataOthers(doc.data?.[0]))
            .catch((err) => console.log(err))
    }, [reload])

    useEffect(() => {
        setWestBank(dataOthers?.delivery.westBank!)
        setInside(dataOthers?.delivery.inside!)
        setJordan(dataOthers?.delivery.jordan!)
    }, [dataOthers])

    const BtnPostInvitationBonus = async () => {
        setStatusBtnCreate(<LoadingBtn />)
        setDisabledBtnCreate(true)
        await axios.post(`${process.env.REACT_APP_URL_API}/API/Others/PostOthersDelivery`, { westBank, inside, jordan })
            .then((doc) => console.log(doc.data))
            .catch((err) => console.log(err))
            .finally(() => {
                setStatusBtnCreate(t("Market.Add bonus"))
                setDisabledBtnCreate(false)
                setReload(!reload)
            })
    }

    const BtnPutInvitationBonus = async () => {
        setStatusBtnPut(<LoadingBtn />)
        setDisabledBtnPut(true)
        await axios.put(`${process.env.REACT_APP_URL_API}/API/Others/PutOthersDelivery/${dataOthers?._id}`, { westBank, inside, jordan })
            .then((doc) => console.log(doc.data))
            .catch((err) => console.log(err))
            .finally(() => {
                setStatusBtnPut(t("Home.Update"))
                setDisabledBtnPut(false)
                setReload(!reload)
            })
    }

    return (<>
        <div style={{ display: "flex", justifyContent: "center" }}>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", maxWidth: "1440px", width: "100%", boxShadow: "rgb(214, 214, 214) 5px 6px 10px", padding: "30px", borderRadius: "10px", border: "solid 1px #d6d6d6", margin: "20px 10% 0 10%" }}>
                <h1 style={{ width: "100%", borderBottom: "1px solid rgb(214, 214, 214)", textAlign: "center", paddingBottom: "20px" }}>{t("Market.InvitationBonus")}</h1>
                <div>
                    <Form.Label>{t('Home.West Bank')} :</Form.Label>
                    <Form.Group className="mb-3" controlId="exampleForm.Phone" style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                        <Form.Control type="NUMBER" placeholder="west Bank" onChange={e => { setWestBank(parseFloat(e.target.value) || undefined) }} defaultValue={dataOthers?.delivery.westBank} />
                    </Form.Group>
                </div>
                <div>
                    <Form.Label>{t('Home.Inside')} :</Form.Label>
                    <Form.Group className="mb-3" controlId="exampleForm.Phone" style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                        <Form.Control type="NUMBER" placeholder="inside" onChange={e => { setInside(parseFloat(e.target.value) || undefined) }} defaultValue={dataOthers?.delivery.inside} />
                    </Form.Group>
                </div>
                <div>
                    <Form.Label>{t('Home.Jordan')} :</Form.Label>
                    <Form.Group className="mb-3" controlId="exampleForm.Phone" style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                        <Form.Control type="NUMBER" placeholder="jordan" onChange={e => { setJordan(parseFloat(e.target.value) || undefined) }} defaultValue={dataOthers?.delivery.jordan} />
                    </Form.Group>
                </div>
                {
                    dataOthers ?
                        <div>
                            <Button onClick={BtnPutInvitationBonus} disabled={disabledBtnPut} bsPrefix="BtnBNB">{statusBtnPut}</Button>
                        </div>
                        :
                        <div>
                            <Button onClick={BtnPostInvitationBonus} disabled={disabledBtnCreate} bsPrefix="BtnBNB">{statusBtnCreate}</Button>
                        </div>
                }
            </div>
        </div>
    </>)
}

export default Delivery;