import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import CartItemCompletePage from "../../../components/cartItem/CartItemCompletePage";
import { useTranslation } from "react-i18next";
import BasicExample from "./Accordion";
import { ChatContext } from "../../../components/context/ChatContext";
import { useContext, useEffect, useState } from "react";
import { subTotall, totall } from "../../../redux/counterSlice";
import axios from "axios";
import { OthersProps } from "../../../Interfaces";




const CompleteOrder: React.FC = () => {
    const CarentItem = useSelector((state: RootState) => state.counterData)
    const userData = useSelector((state: RootState) => state.userData)
    const { t } = useTranslation()
    const chatContext = useContext(ChatContext);
    const { baseCurrency, currency } = chatContext!;
    const total = totall(CarentItem)
    const subTotal = subTotall(CarentItem)
    const [dataOthers, setDataOthers] = useState<OthersProps>()

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_URL_API}/API/Others/GetOthers`)
            .then((doc) => setDataOthers(doc.data?.[0]))
            .catch((err) => console.log(err))
    }, [])
    const location = userData.user.userDetals?.address?.country;

    const Delivery = location && (location === "West Bank" ? dataOthers?.delivery.westBank : location === "Inside" ? dataOthers?.delivery.inside : dataOthers?.delivery.jordan)

    const Total: number = subTotal + Number(Delivery)
    console.log(Delivery)
    console.log(dataOthers?.delivery)
    console.log(userData.user.userDetals?.address?.country)

    const finshNumber = (num: number) => {
        const Currency = currency === "ILS" ? "ILS" : currency === "USD" ? "USD" : "JOD"
        if (currency === "ILS") {
            const afterNum = (Number(num) * baseCurrency)
            return (afterNum.toFixed(0)) + " " + Currency
        }
        else {
            return (Number(num) * baseCurrency).toFixed(2) + " " + Currency
        }
    }


    return (<>
        <div style={{ display: "flex" }}>
            <div style={{ width: "50%" }}>
                {BasicExample()}
            </div>
            <div style={{ width: "50%", backgroundColor: "rgb(241 241 241)", minHeight: "100vh", padding: "0 20px" }}>
                <div style={{ position: "sticky", top: "75px", marginTop: "50px", width: "500px" }}>
                    <div style={{}}>
                        {CarentItem?.cartItems.map((item: any, index) => { return item ? <CartItemCompletePage key={index} data={item} /> : null }
                        )}
                    </div>
                    <div>
                        <div style={{ fontSize: "18px", fontWeight: "500", display: 'flex', width: "500px", justifyContent: "space-between" }}>
                            <div>
                                {t("Market.PayPage.Subtotal")}
                            </div>
                            <div>
                                {finshNumber(total)}
                            </div>
                        </div>
                        <div style={{ fontSize: "18px", fontWeight: "500", display: 'flex', width: "500px", justifyContent: "space-between", color: "red" }}>
                            <div>
                                {t('Market.discount')}
                            </div>
                            <div>
                                - {finshNumber(CarentItem.useWallet ? total - subTotal - CarentItem.valueWallet : total - subTotal)}
                            </div>
                        </div>
                        {
                            CarentItem.useWallet &&
                            <div style={{ fontSize: "18px", fontWeight: "500", display: 'flex', width: "500px", justifyContent: "space-between", color: "red" }}>
                                <div>
                                    {t("Market.PayPage.useWallet")}
                                </div>
                                <div>
                                    - {finshNumber(CarentItem.valueWallet)}
                                </div>
                            </div>
                        }
                        <div style={{ fontSize: "18px", fontWeight: "500", display: 'flex', width: "500px", justifyContent: "space-between" }}>
                            <div>
                                {t("Market.PayPage.shipping")}
                            </div>
                            <div>
                                {finshNumber(Number(Delivery))}
                            </div>
                        </div>
                        <div style={{ fontSize: "18px", fontWeight: "500", display: 'flex', width: "500px", justifyContent: "space-between" }}>
                            <div>
                                {t("Market.PayPage.Total")}
                            </div>
                            <div>
                                {finshNumber(Total)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>)
}

export default CompleteOrder;