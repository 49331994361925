import { Button } from "react-bootstrap";
import { Product } from "../../../admin/homeAdmin/HomeAdmin";
import React, { useContext, useState } from "react";
import Langu from "../../../components/languge/languge";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { decrementItems, incrementItems } from "../../../redux/counterSlice";
import { useNavigate } from "react-router";
import { ChatContext } from "../../../components/context/ChatContext";

interface dataProductsProps {
    Product: Product;
    dragging: boolean;
}

const ClientCardPeoduct: React.FC<dataProductsProps> = ({ Product, dragging }) => {
    const { t } = useTranslation()
    const CarentItem = useSelector((state: RootState) => state.counterData)
    const userData = useSelector((state: RootState) => state.userData);
    const dispatch = useDispatch()
    const nav = useNavigate()
    const context = useContext(ChatContext)
    const { baseCurrency, currency } = context!
    const [hoveredItemId, setHoveredItemId] = useState<string | null>(null)
    const [colorActive, setColorActive] = useState<string>(Product?.colors?.[0]!)

    const getquantityItem = (id: string, colorActive?: string) => {
        const item = CarentItem.cartItems.find((i) => ((i.data._id === id) && (i.color === colorActive)));
        if (item) {
            return item.quantity;
        } else {
            return 0;
        }
    }

    const onClickProduct = (event: React.MouseEvent) => {
        if ((event.target as HTMLElement).classList.contains("BtnBNB")) {
            return;
        }
        else if (!dragging) {
            nav(`/client/market/productDetails/${Product?._id}`)
        }
    };

    const truncateText = (text: string): string => {
        let shortText = text.substring(0, 50)
        if (text.length > 50) {
            shortText = shortText + "..."
        }
        return shortText
    }
    const extractTextFromElement = (element: React.ReactNode): string => {
        if (typeof element === "string") {
            return element;
        }
        if (React.isValidElement(element) && element.props.children) {
            return String(element.props.children);
        }
        return "";
    };

    const finshNumber = (num: number) => {
        const Currency = currency === "ILS" ? "ILS" : currency === "USD" ? "USD" : "JOD"
        if (currency === "ILS") {
            const afterNum = (Number(num) * baseCurrency)
            return (afterNum.toFixed(0)) + " " + Currency
        }
        else {
            return (Number(num) * baseCurrency).toFixed(2) + " " + Currency
        }
    }

    return (<>
        <div
            onMouseEnter={() => setHoveredItemId(Product._id!)}
            onMouseLeave={() => setHoveredItemId(null)}
            style={{ maxWidth: 302, border: "1px solid #dddddd", minHeight: 450 }}
            onClick={onClickProduct}
        >
            <div style={{ display: 'flex', justifyContent: "space-between", flexDirection: "column", alignItems: "center", width: "100%", cursor: "pointer", position: "relative", height: "100%", minHeight: 450 }}>
                <div className="cardHome" style={{ borderRadius: "5px" }} >
                    <img draggable={false} alt="وصف الصورة" src={`${process.env.REACT_APP_URL_API}/API/files/Products/${Product.file ? Product.file[0] : ""}`} />
                    <img draggable={false} alt="وصف الصورة" className="imgHomeHover2" src={`${process.env.REACT_APP_URL_API}/API/files/Products/${Product.file ? Product.file[1] : ""}`} />
                </div>
                {
                    Product.offers &&
                    <div style={{ position: "absolute", top: Product.disable ? "90px" : "20px", left: "20px", backgroundColor: "red", borderRadius: "50%", padding: "10px", color: "white", width: "65px", height: "60px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        {Product.discount! * -1} %
                    </div>
                }
                {
                    Product.disable &&
                    <div style={{ position: "absolute", top: "20px", left: "20px", backgroundColor: "black", borderRadius: "50%", padding: "10px", color: "white", width: "65px", height: "65px", display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center" }}>
                        {t("Market.Sold out")}
                    </div>
                }
                {Product.colors?.length! > 0 &&
                    <div style={{ display: "flex" }}>
                        {Product.colors?.map((color: string, index) => (
                            <Button
                                key={index}
                                onClick={() => { setColorActive(color); dragging = true }}
                                style={{ backgroundColor: color, minWidth: 25, minHeight: 25, border: "1px solid #dddddd", margin: "10px 5px", boxShadow: colorActive === color ? `0px 0px 10px 5px ${colorActive}` : '' }}>
                            </Button>
                        ))}
                    </div>
                }
                {
                    hoveredItemId === Product._id && (
                        getquantityItem(Product?._id, colorActive) === 0 ?
                            <div className="BtnAddMarket" style={{ position: "absolute", top: "250px" }}>
                                <Button bsPrefix="BtnBNB" disabled={Product.disable} onClick={() => dispatch(incrementItems({ quantity: 1, data: Product, userId: userData.user.userDetals?._id!, color: colorActive }))} style={{ borderRadius: "20px" }}>{t("Market.add to cart")}</Button>
                            </div>
                            :
                            <div className="BtnAddMarket" style={{ position: "absolute", top: "250px", display: "flex", alignItems: "center", gap: "2px" }}>
                                <div>
                                    <Button bsPrefix="BtnBNB" onClick={() => dispatch(incrementItems({ quantity: 1, data: Product, userId: userData.user.userDetals?._id!, color: colorActive }))} disabled={getquantityItem(Product?._id!) === Product.number} style={{ borderRadius: "15px", width: "30px", height: "30px", padding: 0 }}>+</Button>
                                </div>
                                <div style={{ backgroundColor: "#842cff", color: "white", padding: "5px 10px", borderRadius: "15px" }}>
                                    {getquantityItem(Product?._id!, colorActive)} {t("Market.in cart")}
                                </div>
                                <div>
                                    <Button bsPrefix="BtnBNB" onClick={() => dispatch(decrementItems({ quantity: 1, data: Product, userId: userData.user.userDetals?._id!, color: colorActive }))} style={{ borderRadius: "15px", width: "30px", height: "30px", padding: 0 }}>-</Button>
                                </div>
                            </div>
                    )
                }
                <div style={{ fontSize: "20px", padding: "5px" }}>{truncateText(extractTextFromElement(Langu(Product.product!)))}</div>
                <div style={{ width: "100%", fontSize: "15px" }}>
                    {
                        Product.newPrice ?
                            <div style={{ display: "flex", width: "100%", justifyContent: "center", gap: '20px' }}>
                                <div style={{ textDecoration: "line-through", color: "gray", fontSize: 20, fontWeight: 600 }}>
                                    {finshNumber(Product.price!)}
                                </div>
                                <div style={{ color: "red", fontSize: 20, fontWeight: 600 }}>
                                    {finshNumber(Product.newPrice!)}
                                </div>
                            </div>
                            :
                            <div style={{ textAlign: "center", color: "gray", fontSize: 20, fontWeight: 600 }}>
                                {finshNumber(Product.price!)}
                            </div>
                    }
                </div>
            </div>
        </div>
    </>)
}

export default ClientCardPeoduct;