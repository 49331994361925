import { Button, CloseButton } from 'react-bootstrap';
import './HedarGuest.css'
import LoginModal from './LoginModal';
import { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { FaRegUser } from "react-icons/fa";
import { IoSearch } from "react-icons/io5";
import { MdOutlineShoppingCart } from "react-icons/md";
import { GrFavorite } from "react-icons/gr";
import Search from './Search';
import ShoppingCart from './ShoppingCart';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { useTranslation } from 'react-i18next';
import I18nGuest from './I18n.guest';
import { ThemeColor } from '../../theme';
import { offerHedarRedux } from '../../redux/counterSlice';
import axios from 'axios';
import { Category } from '../../admin/homeAdmin/HomeAdmin';
import Langu from '../../components/languge/languge';
import SetCurrency from '../../components/setCurrency/SetCurrency';



const HedarGuest: React.FC = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch();

    const [showModalLogin, setShowModalLogin] = useState<boolean>(false)
    const [showSearch, setShowSearch] = useState<boolean>(false)
    const [showShoppingCart, setShowShoppingCart] = useState<boolean>(false)
    const [dataCategotys, setDataCategorys] = useState<Category[]>()

    const CarentItem = useSelector((state: RootState) => state.counterData)
    const [isVisible, setIsVisible] = useState<boolean>(true);
    const [close, setClose] = useState<Boolean>(isVisible)

    const handleClose = () => {
        // جعل العنصر يختفي تدريجيًا
        setIsVisible(false);
        // بعد فترة التأثير نحدث حالة Redux
        setTimeout(() => {
            setClose(false)
            dispatch(offerHedarRedux({ offerHedar: true }));
        }, 700);
    };

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_URL_API}/API/Products/GetCategorys`)
            .then((doc) => setDataCategorys(doc.data))
            .catch((err) => console.log(err))
    }, [])

    return (<>
        <nav>
            <div style={{ minHeight: close ? "85px" : "65px" }}>
                <div className="NavHedar" style={{ flexDirection: "column" }}>
                    {!CarentItem.offerHedar && (
                        <div
                            className="offer-banner"
                            style={{
                                backgroundColor: ThemeColor.bgColor(1),
                                color: "white",
                                display: "flex",
                                justifyContent: "space-between",
                                padding: 5,
                                minHeight: 20,
                                minWidth: "100%",
                                opacity: isVisible ? 1 : 0, // تأثير الإخفاء التدريجي
                                transition: "opacity 0.7s ease-out", // تأثير الانتقال
                            }}
                        >
                            <div></div>
                            <div style={{ fontSize: 14, fontWeight: 500 }}>
                                الشحن لفلسطين والاردن والدفع عند الاستلام 20 شيكل ,القدس 30شيكل ,الداخل 60شيكل
                            </div>
                            <div>
                                <CloseButton
                                    variant="white"
                                    style={{ margin: "0 20px" }}
                                    onClick={handleClose}
                                />
                            </div>
                        </div>
                    )}
                    <div style={{ maxWidth: "1440px", width: "100%", padding: "0 40px", minHeight: 65, alignContent: "center" }}>
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <div>
                                <div className='MenuNavLink' style={{ display: "flex", gap: "20px", flexDirection: "row" }}>
                                    <NavLink className={"NavLinkHedar"} to="/guest/homePage">{t("Home.home")}</NavLink>
                                    {dataCategotys?.slice(0, 7).map((Categoty, index) => (
                                        <NavLink className={"NavLinkHedar"} to={`/guest/category/${Categoty.name.en}`} key={index}><Langu {...Categoty.name} /></NavLink>
                                    ))}
                                </div>
                            </div>
                            <div style={{ display: "flex", gap: "15px", alignItems: "center" }}>
                                <Button bsPrefix='BtnBNBIcon' style={{ display: "flex" }} onClick={() => setShowShoppingCart(true)}>
                                    <div style={{ backgroundColor: "#842cff", minWidth: "24px", borderRadius: "50%", color: "white" }}>0</div>
                                    <MdOutlineShoppingCart className='IconHedar' size={"25px"} />
                                </Button>
                                <Button bsPrefix='BtnBNBIcon' style={{ display: "flex", gap: "2px" }} onClick={() => { }}>
                                    <div style={{ backgroundColor: "#842cff", minWidth: "24px", borderRadius: "50%", color: "white", }}>0</div>
                                    <GrFavorite className='IconHedar' size={"25px"} />
                                </Button>
                                <Button bsPrefix='BtnBNBIcon' onClick={() => setShowModalLogin(true)}><FaRegUser className='IconHedar' size={"22px"} /></Button>
                                <Button bsPrefix='BtnBNBIcon' onClick={() => setShowSearch(true)}><IoSearch className='IconHedar' size={"25px"} /> </Button>
                                <div>
                                    <SetCurrency />
                                </div>
                                <div>
                                    <I18nGuest />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <LoginModal show={showModalLogin} setShowModalLogin={setShowModalLogin} />
            <Search show={showSearch} setShowSearch={setShowSearch} />
            <ShoppingCart show={showShoppingCart} setShowShoppingCart={setShowShoppingCart} />
        </nav>
    </>)
}

export default HedarGuest;