import axios from "axios";
import { useEffect, useState } from "react";
import { UsersProps } from "../../admin/homeAdmin/HomeAdmin";
import { useParams } from "react-router";
import { useTranslation } from "react-i18next";
import { Button, Form } from "react-bootstrap";
import ModaleChangePassword from "./ModaleChangePassword";
import ModaleChangeEmail from "./ModaleChangeEmail";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { LoadingBtn } from "../../components/laoding/laoding";
import img from '../../files/HomeGuest/9.png'


const AccountDetals: React.FC = () => {
    const userData = useSelector((state: RootState) => state.userData)
    let token = userData.user.token
    const { t } = useTranslation()
    let params = useParams()
    const [dataUser, setDataUser] = useState<UsersProps>()
    const [showModaleChangePassword, setShowModalChangePassword] = useState<boolean>(false)
    const [showModaleChangeEmail, setShowModalChangeEmail] = useState<boolean>(false)
    const [userName, setUserName] = useState<string>()
    const [location, setLocation] = useState<string>()
    const [phone, setPhone] = useState<number | undefined>()
    const [statusBtnChange, setStatusBtnChange] = useState<any>(t("Home.Account.change"))
    const [disabledBtn, setDisabledBtn] = useState<boolean>(false)
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_URL_API}/API/Users/GetUser/${params.id}`,
            {
                headers: {
                    Authorization: "Bearer " + userData.user.token,
                }
            }
        )
            .then((doc) => setDataUser(doc.data))
            .catch((err) => console.log(err))
    }, [params.id, userData.user.token])

    useEffect(() => {
        setUserName(dataUser?.userName!)
        setLocation(dataUser?.address?.country)
        setPhone(dataUser?.phone)
    }, [dataUser])

    const BtnChangeAccount = async () => {
        setStatusBtnChange(<LoadingBtn />)
        setDisabledBtn(true)
        await axios.put(`${process.env.REACT_APP_URL_API}/API/Users/PutUserId/${params.id}`, { userName, phone, location },
            {
                headers: {
                    Authorization: "Bearar " + token,
                }
            })
            .then((doc) => console.log(doc.data))
            .catch((err) => console.log(err))
            .finally(() => {
                setStatusBtnChange(t("Home.Account.change"))
                setDisabledBtn(false)
            })
    }

    return (<>
        <div style={{ background: `url(${img}) no-repeat center center`, backgroundSize: 'cover', minHeight: "20vh", width: '100%' }}>
            {/* <div className="nameOffer" style={{ top: "calc(10vh + 60px)" }}>{t("Home.ForYouMyLady")}</div> */}
        </div>
        <div style={{ display: "flex", justifyContent: "center", fontSize: "18px" }}>
            <div style={{ maxWidth: "1040px", width: "100%", padding: "20px", border: "1px solid #dddddd", borderRadius: "20px", margin: "50px", display: 'flex', flexDirection: 'column', gap: "5px", alignItems: "center" }}>
                <div style={{ width: "100%", maxWidth: "700px", textAlign: "center" }}>
                    <Form.Group className="mb-3" controlId="exampleForm.name" >
                        <Form.Label>user name :</Form.Label>
                        <Form.Control style={{ textAlign: "center" }} type="text" placeholder="user name" autoFocus onChange={e => { setUserName(e.target.value) }} defaultValue={dataUser?.userName} />
                    </Form.Group>
                </div>
                <div style={{ width: "100%", maxWidth: "700px", textAlign: "center" }}>
                    <Form.Group className="mb-3" controlId="exampleForm.Category" style={{ width: "100%" }}>
                        <Form.Label>{t("Home.location")} :</Form.Label>
                        <Form.Select style={{ textAlign: "center" }} onChange={e => { setLocation(e.target.value) }} value={location}>
                            <option value={"West Bank"}>{t("Home.West Bank")}</option>
                            <option value={"Inside"}>{t("Home.Inside")}</option>
                            <option value={"Jordan"}>{t("Home.Jordan")}</option>
                        </Form.Select>
                    </Form.Group>
                </div>
                <div style={{ display: 'flex', alignItems: "center", justifyContent: "space-between", width: "100%", maxWidth: 700 }}>
                    <div>
                        {t("Home.email")} : {dataUser?.email}
                    </div>
                    <div>
                        <Button bsPrefix="BrandShoppBrand" onClick={() => setShowModalChangeEmail(true)} style={{ backgroundColor: "white", fontSize: "16px", color: 'blue' }}>
                            {t("Home.Account.change")}
                        </Button>
                    </div>
                </div>
                <div style={{ display: 'flex', alignItems: "center", justifyContent: "space-between", width: "100%", maxWidth: 700 }}>
                    <div>
                        {t("Hedar.Password")} : *********
                    </div>
                    <div>
                        <Button bsPrefix="BrandShoppBrand" onClick={() => setShowModalChangePassword(true)} style={{ backgroundColor: "white", fontSize: "16px", color: 'blue' }}>
                            {t("Home.Account.change")}
                        </Button>
                    </div>
                </div>
                <div style={{ width: "100%", maxWidth: "700px", textAlign: "center" }}>
                    <Form.Group className="mb-3" controlId="exampleForm.Phone">
                        <Form.Label>Phone :</Form.Label>
                        <Form.Control type="NUMBER" style={{ textAlign: "center" }} placeholder="Phone" onChange={e => { setPhone(parseFloat(e.target.value) || undefined) }} defaultValue={dataUser?.phone} />
                    </Form.Group>
                </div>
                <div>
                    <Button bsPrefix="BtnBNB" disabled={disabledBtn} onClick={BtnChangeAccount}>{statusBtnChange}</Button>
                </div>
            </div>
            <ModaleChangePassword show={showModaleChangePassword} setShowModalChangePassword={setShowModalChangePassword} userId={dataUser?._id!} />
            <ModaleChangeEmail show={showModaleChangeEmail} setShowModalChangeEmail={setShowModalChangeEmail} />
        </div>
    </>)
}

export default AccountDetals;