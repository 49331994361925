import { createContext, ReactNode, useCallback, useEffect, useState } from "react";
import { OrderProps } from "../../admin/market/orders/getOrders/GetOrders.admin";
import { UsersProps } from "../../admin/homeAdmin/HomeAdmin";
import { io, Socket } from "socket.io-client";
import axios from "axios";
import { cleenItems } from "../../redux/counterSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { RootState } from "../../redux/store";




interface OrderContextProviderProps {
    children: ReactNode;
    user?: UsersProps;
}

interface OrderContexttype {
    notificationOrder: any;
    createOrder: (order: OrderProps) => Promise<void>
}



export const OrderContext = createContext<OrderContexttype | undefined>(undefined);

export const OrderContextProvider = ({ children, user }: OrderContextProviderProps) => {
    const userData = useSelector((state: RootState) => state.userData)
    const dispatch = useDispatch()
    const nav = useNavigate()
    const [socket, setSocket] = useState<Socket>()
    const [order, setOrder] = useState<OrderProps>()
    const [newOrder, setNewOrder] = useState<OrderProps>()
    const [notificationOrder, setNotificationOrder] = useState<any>()

    useEffect(() => {
        const newSocket = io(userData?.user?.token && process.env.REACT_APP_URL_API)
        setSocket(newSocket)
        return () => { newSocket.disconnect() }
    }, [user])

    useEffect(() => {
        if (socket === null) return;

        socket?.emit("createOrder", newOrder)

    }, [newOrder])


    useEffect(() => {
        if (socket === null) return
        // socket?.emit("createOrder", newOrder?._id)
        socket?.on("getOrder", (res: any) => {
            console.log(111)
            setOrder(res)
        })
        socket?.on("getNotificationOrder", async (res: any) => {
            // const isChatOpen = currentChat?.members?.some(id =>
            //     id === res.senderId
            // )
            let isChatOpen
            if (isChatOpen) {
                setNotificationOrder((prev: any) => [{ ...res, isRead: true }, ...prev])
                // await axios.put(`${process.env.REACT_APP_URL_API}/API/Message/IsReadMessage`, { id: res._id, isRead: true })
            } else {
                setNotificationOrder((prev: any) => [res, ...prev])
            }
        })
        return () => {
            socket?.off("getOrder")
            socket?.off("getNotificationOrder")
        }
    }, [socket, newOrder])


    const createOrder = useCallback(async (order: OrderProps) => {
        if (!order) return console.log("err create order...")
        await axios.post(`${process.env.REACT_APP_URL_API}/API/Orders/PostOrder`, order)
            .then((doc) => {
                setNewOrder(doc.data)
                dispatch(cleenItems())
                nav(`/client/market/invoice/${doc.data._id}`)
            })
            .catch((err) => console.log(err))
    }, [])


    return (
        <OrderContext.Provider value={{
            notificationOrder,
            createOrder
        }}>
            {children}
        </OrderContext.Provider>
    )
}