import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { WalletProps } from "../../Interfaces";
import { UsersProps } from "../../admin/homeAdmin/HomeAdmin";



const Wallet = () => {
    const userData = useSelector((state: RootState) => state.userData)
    const [dataWallet, setDataWallet] = useState<WalletProps[]>()
    const [newDataUser, setNewDataUser] = useState<UsersProps>()
    const dispatch = useDispatch()
    console.log("dataWallet : ", dataWallet)
    console.log("newDataUser : ", newDataUser)

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_URL_API}/API/Wallets/GetWalletId/${userData.user.userDetals?.invitationName}`, {
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + userData?.user?.token,
            }
        })
            .then((doc) => setDataWallet(doc.data))
            .catch((err) => console.log(err))
        axios.get(`${process.env.REACT_APP_URL_API}/API/Users/GetUser/${userData.user.userDetals?._id}`, {
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + userData?.user?.token,
            }
        })
            .then((doc) => { setNewDataUser(doc.data); })
            .catch((err) => console.log(err))
    }, [])


    return (<>

    </>)
}

export default Wallet;