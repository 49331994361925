import axios from "axios"
import { useEffect, useState } from "react"
import { Button, Form } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router"
import { LoadingBtn } from "../../components/laoding/laoding"



const ForgotPasswordPage: React.FC = () => {
    const { t } = useTranslation()
    const param = useParams()
    const [password, setPassword] = useState<string>()
    const [confirmPassword, setConfirmPassword] = useState<string>()
    const [success, setSuccess] = useState<boolean>(false)
    const [statusBtn, setStatusBtn] = useState<any>(t("Hedar.Forgot Password"))
    const [disabledBtn, setDisabledBtn] = useState<boolean>(false)
    const [errMsg, setErrMsg] = useState<string>()
    const [successForgot, setSuccessForgot] = useState<string>()

    useEffect(() => {
        axios.post(`${process.env.REACT_APP_URL_API}/API/Auth/VerifyTokenForgotPassword/${param.token}`)
            .then(() => setSuccess(true))
            .catch(() => setSuccess(false))
    }, [param.token])

    useEffect(() => {
        setErrMsg('')
        setSuccessForgot('')
    }, [password, confirmPassword])
    const dataForgotPassword = { password, confirmPassword }

    const BtnForgotPassword = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | React.KeyboardEvent<HTMLInputElement>) => {
        setDisabledBtn(true)
        setStatusBtn(<LoadingBtn />)
        axios.put(`${process.env.REACT_APP_URL_API}/API/Auth/ResetPassword/${param.token}`, dataForgotPassword)
            .then((doc) => setSuccessForgot(doc.data))
            .catch((err) => { setErrMsg(err?.response?.data?.errors?.[0].msg); console.log(err) })
        setStatusBtn(t("Hedar.Forgot Password"))
        setDisabledBtn(false)
    }

    const handleKeyPressLogin = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            BtnForgotPassword(event);
        }
    };


    if (!success) return <div style={{ color: "red", fontWeight: 500, textAlign: "center", marginTop: "30vh" }}>انتهاء صلاحية الكود</div>
    return (<>
        <div style={{ display: "flex", justifyContent: "center" }}>

            <div style={{ maxWidth: 800, minWidth: 500, border: "1px solid #dddddd", borderRadius: 20, padding: 40, marginTop: 60 }}>
                <Form.Group controlId="exampleForm.password">
                    <Form.Label>{t("Home.Account.NewPassword")} :</Form.Label>
                    <Form.Control onKeyDown={handleKeyPressLogin} type="password" placeholder="" autoFocus onChange={e => setPassword(e.target.value)} />
                </Form.Group>
                <Form.Group controlId="exampleForm.confirmPassword">
                    <Form.Label>{t("Home.Account.ConfirmPassword")} :</Form.Label>
                    <Form.Control onKeyDown={handleKeyPressLogin} type="password" placeholder="" autoFocus onChange={e => setConfirmPassword(e.target.value)} />
                </Form.Group>
                {errMsg && <div style={{ color: "red", width: "100%", textAlign: "center", padding: "5px", backgroundColor: "#ffe9e9", borderRadius: "5px", marginTop: 5 }}>
                    {errMsg}
                </div>}
                {successForgot && <div style={{ color: "green", fontWeight: "500", width: "100%", textAlign: "center", padding: "5px", backgroundColor: "rgb(199 237 200)", borderRadius: "5px", marginTop: 5 }}>
                    {t("Hedar.Password has been changed.")}
                </div>}
                <div style={{ textAlign: "center", marginTop: 30 }}>
                    <Button bsPrefix="BtnBNB" onClick={BtnForgotPassword} disabled={disabledBtn}>{statusBtn}</Button>
                </div>
            </div>
        </div>

    </>)
}

export default ForgotPasswordPage;