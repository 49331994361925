import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import { OrderProps } from "../../admin/market/orders/getOrders/GetOrders.admin";
import { useTranslation } from "react-i18next";
import { Table } from "react-bootstrap";
import Langu from "../../components/languge/languge";
import Refund from "../market/payPage/Refund/Refund";
import { RootState } from "../../redux/store";
import { useSelector } from "react-redux";
import { ChatContext } from "../../components/context/ChatContext";


const OrderDetalsClient: React.FC = () => {
    const userData = useSelector((state: RootState) => state.userData);
    const { t } = useTranslation()
    const conext = useContext(ChatContext)
    const { currency, baseCurrency } = conext!
    let params = useParams()
    const [dataOrder, setDataOrder] = useState<OrderProps>()
    const [refundTriggered, setRefundTriggered] = useState<boolean>(false);

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_URL_API}/API/Orders/GetOrder/${params.id}`,
            {
                headers: {
                    Authorization: "Bearer " + userData.user.token,
                }
            }
        )
            .then((doc) => setDataOrder(doc.data))
            .catch((err) => console.log(err))
    }, [refundTriggered])

    const subTotal: number = dataOrder?.dataItems?.reduce((total, cartitem) => {
        return total + (cartitem.price || 0) * cartitem.quantity!
    }, 0)!

    const finelTotal: number = dataOrder?.dataItems?.reduce((total, cartitem) => {
        return total + ((cartitem.newPrice || cartitem.price || 0) * cartitem.quantity!) - ((cartitem.newPrice || cartitem.price || 0) - (cartitem.offerForTwoProducts && cartitem.quantity! > 1 && cartitem.priceOfferForTwoProducts || cartitem.newPrice || cartitem.price || 0))
    }, 0)!

    const ProductDiscount: number = dataOrder?.dataItems?.reduce((total, cartitem) => {
        return total + ((cartitem.price! - cartitem.newPrice!) || 0) * cartitem.quantity!
    }, 0)!

    const offerForTwoProducts = dataOrder?.dataItems?.reduce((total, cartitem) => {
        const totall = total + (cartitem.offerForTwoProducts && (cartitem.newPrice! - cartitem.priceOfferForTwoProducts!) || 0)
        return cartitem.quantity! < 2 ? 0 : totall
    }, 0)!

    const stagesArray = ["viewed", "in preparation", "on the way", "delivery done"]

    const isStageCompleted = (stage: string) => {
        const currentIndex = stagesArray.indexOf(dataOrder?.status!);
        const stageIndex = stagesArray.indexOf(stage);
        return stageIndex <= currentIndex;
    };

    const finshNumber = (num: number) => {
        const Currency = currency === "ILS" ? "ILS" : currency === "USD" ? "USD" : "JOD"
        if (currency === "ILS") {
            const afterNum = Math.round(Number(num) * baseCurrency)
            const remainder = afterNum % 10;
            if (remainder < 5) {
                return (afterNum - remainder).toLocaleString('en-US') + " " + Currency
            } else {
                return (afterNum + (10 - remainder)).toLocaleString('en-US') + " " + Currency
            }
        }
        else {
            return Math.round(Number(num) * baseCurrency) + " " + Currency
        }
    }

    const handleRefund = () => {
        setRefundTriggered((prev) => !prev);
    };
    console.log(offerForTwoProducts)
    console.log(dataOrder)

    return (<>
        <div style={{ display: "flex", justifyContent: "center" }}>
            <div style={{ maxWidth: "1040px", width: "100%", padding: "20px", border: "1px solid #dddddd", borderRadius: "20px", margin: "50px" }}>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <div>
                            {t("Home.name")}: {dataOrder?.userName}
                        </div>
                        <div>
                            {t("Market.PayPage.id Order")} :  {dataOrder?._id}
                        </div>
                    </div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <div>
                            {t("Home.phone")}: {dataOrder?.phone}
                        </div>
                        <div>
                            {t("Market.Timing")} :{new Date(dataOrder?.createdAt!).toLocaleString('en-GB', {
                                year: 'numeric',
                                month: '2-digit',
                                day: '2-digit',
                                hour: '2-digit',
                                minute: '2-digit',
                                hour12: false
                            })}
                        </div>
                    </div>
                </div>
                <div>
                    <Table striped style={{ border: "1px solid #dddddd" }}>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th style={{ width: "50%", minWidth: "50%" }}>{t("Market.product")}</th>
                                <th>{t("Market.Total price")}</th>
                                <th>{t("Market.Quantity")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {dataOrder?.dataItems?.map((item, index) => (
                                <tr key={index}>
                                    <td >
                                        {index + 1}
                                    </td>
                                    <td style={{ width: "50%" }}>
                                        <Langu {...item?.product!} /> {item.color && <div style={{ display: "flex", gap: 5 }}>  <div style={{ minWidth: 20, maxWidth: 20, minHeight: 20, maxHeight: 20, backgroundColor: item.color }}></div></div>}
                                    </td>
                                    <td>
                                        {
                                            item.newPrice ?
                                                <div style={{ display: "flex", width: "100%", gap: '20px' }}>
                                                    <div style={{ textDecoration: "line-through", color: "gray", fontSize: 18, fontWeight: 500 }}>
                                                        {finshNumber(item.price!)}
                                                    </div>
                                                    <div style={{ color: "red", fontSize: 18, fontWeight: 500 }}>
                                                        {finshNumber(item.newPrice)}
                                                    </div>
                                                </div>
                                                :
                                                <div style={{ textAlign: "center", color: "gray", fontSize: 18, fontWeight: 500 }}>
                                                    {finshNumber(item.price!)}
                                                </div>
                                        }
                                    </td>
                                    <td>
                                        {item.quantity}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
                <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
                    <div style={{ padding: "10px", borderRadius: "10px", width: "50%" }}>
                        <div style={{ display: "flex", justifyContent: "space-around", fontSize: 17, fontWeight: 500, color: "green" }}>
                            <div style={{ width: 200 }}>
                                {t("Market.PayPage.Subtotal")}:
                            </div>
                            <div style={{ width: 100 }}>
                                {finshNumber(subTotal)}+
                            </div>
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-around", fontSize: 17, fontWeight: 500, color: "green" }}>
                            <div style={{ width: 200 }}>
                                {t("Market.PayPage.shipping")} :
                            </div>
                            <div style={{ width: 100 }}>
                                {finshNumber(dataOrder?.delivery!)}+
                            </div>
                        </div>
                        {
                            dataOrder?.useWallet &&
                            <div style={{ display: "flex", justifyContent: "space-around", fontSize: 17, fontWeight: 500, color: "red" }}>
                                <div style={{ width: 200 }}>
                                    {t("Market.PayPage.useWallet")} :
                                </div>
                                <div style={{ width: 100 }}>
                                    {finshNumber(dataOrder.valueWallet)}-
                                </div>
                            </div>
                        }
                        {
                            ProductDiscount !== 0 &&
                            <div style={{ display: "flex", justifyContent: "space-around", fontSize: 17, fontWeight: 500, color: "red" }}>
                                <div style={{ width: 200 }}>
                                    {t("Market.PayPage.Product discount discount")} :
                                </div>
                                <div style={{ width: 100 }}>
                                    {finshNumber(ProductDiscount)}-
                                </div>
                            </div>
                        }
                        {
                            offerForTwoProducts !== 0 &&
                            <div style={{ display: "flex", justifyContent: "space-around", fontSize: 17, fontWeight: 500, color: "red" }}>
                                <div style={{ width: 200 }}>
                                    {t("Market.PayPage.Second product discount")} :
                                </div>
                                <div style={{ width: 100 }}>
                                    {finshNumber(offerForTwoProducts)}-
                                </div>
                            </div>
                        }
                        <div style={{ display: "flex", justifyContent: "space-around", fontSize: 20, fontWeight: 600 }}>
                            <div style={{ width: 200 }}>
                                {t("Market.PayPage.Total")} :
                            </div>
                            <div style={{ width: 100 }}>
                                {finshNumber(finelTotal + dataOrder?.delivery!)}
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ textAlign: "center", margin: "20px" }}>
                    {t("Market.PayPage.Payment")} :  {t(`Market.PayPage.${dataOrder?.paymentMethod}`)}
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <div style={{ border: "1px solid #dddddd", padding: "10px", borderRadius: "10px", width: "50%" }}>
                        <div style={{ display: "flex", gap: "3px" }}>
                            <div>
                                {t("Market.PayPage.Country")} : {dataOrder?.address?.country} ,
                            </div>
                            <div>
                                {t("Market.PayPage.Neighborhood")} : {dataOrder?.address?.neighborhood}
                            </div>
                        </div>
                        <div style={{ display: "flex", gap: "3px" }}>
                            <div>
                                {t("Market.PayPage.Street")} : {dataOrder?.address?.street} ,
                            </div>
                            <div>
                                {t("Market.PayPage.Building number")} : {dataOrder?.address?.buildingNumber} ,
                            </div>
                            <div>
                                {t("Market.PayPage.floor")} : {dataOrder?.address?.floor} ,
                            </div>
                            <div>
                                {t("Market.PayPage.Apartment No")} : {dataOrder?.address?.apartmentNo}
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ display: "flex", justifyContent: "center", margin: "20px" }}>
                    {t("Market.status")} :  {t(`Market.PayPage.${dataOrder?.status}`)}
                </div>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 30 }}>
                    {
                        dataOrder?.status !== "canceled" && stagesArray.map((status, index) => (
                            <div key={index} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '10px', }}>
                                <StageCircle completed={isStageCompleted(status)} text={status} />
                            </div>
                        ))
                    }
                </div>
                <div style={{ display: "flex", justifyContent: "center", margin: "20px" }}>
                    <Refund dataOrder={dataOrder!} onRefund={handleRefund} />
                </div>
            </div>
        </div>
    </>)
}

interface StageCircleProps {
    completed: boolean;
    text: string;
}

const StageCircle: React.FC<StageCircleProps> = ({ completed, text }) => {
    const { t } = useTranslation()
    return (<>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '0 5px' }}>
            <div style={{
                width: '15px',
                height: '15px',
                borderRadius: '50%',
                backgroundColor: completed ? '#7c1eff' : 'white',
                border: "1px solid #7c1eff",
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: 'white'
            }}>
            </div>
            <p>{t(`Market.PayPage.${text}`)}</p>
        </div>
    </>)
};

export default OrderDetalsClient;