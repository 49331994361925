import { Button } from "react-bootstrap";
import CategorysClient from "./Categorye.client";
import NewProductsClient from "./NewProducts.client";
import Offer1Client from "./Offer1.client";
import Product1Client from "./Product1.client";
import img from '../../files/HomeGuest/ضصثضصيسش.jpg'
import PackagesClient from "./Packages.client";
import MostCommonClient from "./MostCommon.client";
import ShoppBrandClient from "./ShoppBrand.client";
import { useTranslation } from "react-i18next";
import AllProductsClient from "./AllProducts.client";
import { useEffect } from "react";
import ForYouMyLady from "./ForYouMyLady";
import MostLikedClient from "./MostLiked.client";



const HomeClient: React.FC = () => {
    const { t } = useTranslation()
    useEffect(() => { window.scrollTo(0, 0) }, [])
    return (<>
        <div>
            <div>
                <Offer1Client />
            </div>
            <div>
                <CategorysClient />
            </div>
            <div>
                <ForYouMyLady />
            </div>
            <div>
                <NewProductsClient />
            </div>
            <div>
                <Product1Client />
            </div>
            <div style={{ margin: "20px 0" }}>
                <div
                    style={{
                        minHeight: "100vh", width: "100%", backgroundImage: `url(${img})`, backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        display: "flex", justifyContent: "center", alignItems: "center"
                    }}
                >
                    <Button bsPrefix="BtnFollowusonInstagram">{t("Home.FollowusonInstagram")}</Button>
                </div>
            </div>
            <div>
                <PackagesClient />
            </div>
            <div>
                <MostCommonClient />
            </div>
            <div>
                <MostLikedClient />
            </div>
            <div>
                <ShoppBrandClient />
            </div>
            <div>
                <AllProductsClient />
            </div>
        </div>
    </>)
}

export default HomeClient;