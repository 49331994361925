import "./Home.css"
import Offer1Guest from "./Offer1.guest"
import CategorysGuest from "./Categorye.guest"
import Product1Guest from "./Product1.guest"
import NewProductsGuest from "./NewProducts.guest"
import PackagesGuest from "./Packages.guest"
import MostCommonGuest from "./MostCommon.guest"
import ShoppBrandGuest from "./ShoppBrand.guest"
import ForYouMyLadyGuset from "../../client/homeClient/ForYouMyLadyGuest"
import { Button } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import img from '../../files/HomeGuest/ضصثضصيسش.jpg'
import MostLikedGuest from "./MostLiked.guest"
import AllProductsGuest from "./AllProductsGuest"


const Home: React.FC = () => {

    const { t } = useTranslation()
    return (<>
        <div>
            <div>
                <div>
                    <Offer1Guest />
                </div>
                <div>
                    <CategorysGuest />
                </div>
                <div>
                    <ForYouMyLadyGuset />
                </div>
                <div>
                    <NewProductsGuest />
                </div>
                <div>
                    <Product1Guest />
                </div>
                <div style={{ margin: "20px 0" }}>
                    <div
                        style={{
                            minHeight: "100vh", width: "100%", backgroundImage: `url(${img})`, backgroundRepeat: "no-repeat",
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            display: "flex", justifyContent: "center", alignItems: "center"
                        }}
                    >
                        <Button bsPrefix="BtnFollowusonInstagram">{t("Home.FollowusonInstagram")}</Button>
                    </div>
                </div>
                <div>
                    <PackagesGuest />
                </div>
                <div>
                    <MostCommonGuest />
                </div>
                <div>
                    <MostLikedGuest />
                </div>
                <div>
                    <ShoppBrandGuest />
                </div>
                <div>
                    <AllProductsGuest />
                </div>
            </div>
        </div>
    </>)
}

export default Home;