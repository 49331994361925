import { Accordion, Alert, Button, Form } from "react-bootstrap";
import Langu from "../../../components/languge/languge";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ChatContext } from "../../../components/context/ChatContext";
import { Product } from "../../../admin/homeAdmin/HomeAdmin";
import CompleteInformation from "../../../client/components/ProductDetailsClient/CompleteInformation";
import { FaRegHeart, FaShareAlt } from "react-icons/fa";

interface InfoProductProps {
    data: Product;
}

const InfoProduct: React.FC<InfoProductProps> = ({ data }) => {
    const { t } = useTranslation()
    const [colorActive, setColorActive] = useState<string>(data?.colors?.[0]!)
    const [showAlirtMsg, setShowAlirtMsg] = useState<boolean>(false)
    const context = useContext(ChatContext)
    const { isMobile, baseCurrency, currency } = context!

    useEffect(() => {
        setColorActive(data?.colors?.[0]!)
    }, [data])

    const finshNumber = (num: number) => {
        const Currency = currency === "ILS" ? "ILS" : currency === "USD" ? "USD" : "JOD"
        if (currency === "ILS") {
            const afterNum = (Number(num) * baseCurrency)
            return (afterNum.toFixed(0)) + " " + Currency
        }
        else {
            return (Number(num) * baseCurrency).toFixed(2) + " " + Currency
        }
    }

    return (<>
        <div style={{ padding: 10 }}>
            <div style={{ fontWeight: 700, fontSize: 22 }}><Langu {...data.product!} /></div>
            <div style={{ width: "100%", fontSize: "18px", margin: '10px 0' }}>
                {
                    data.newPrice ?
                        <div style={{ display: "flex", width: "100%", justifyContent: "start", gap: '20px', fontWeight: 500, flexDirection: "column" }}>
                            <div>
                                {t('Market.price')} :
                            </div>
                            <div style={{ display: "flex", gap: 10 }}>
                                <div style={{ textDecoration: "line-through", color: "black", fontWeight: 700, fontSize: 25 }}>
                                    {finshNumber(data.price!)}
                                </div>
                                <div style={{ color: "red", fontWeight: 700, fontSize: 25 }}>
                                    {t('Market.newPrice')} : {finshNumber(data.newPrice!)}
                                </div>
                            </div>
                        </div>
                        :
                        <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
                            <div style={{ textAlign: "start", color: "black", fontWeight: 700 }}>
                                {t('Market.price')} :
                            </div>
                            <div style={{ textAlign: "start", color: "black", fontWeight: 700, fontSize: 25 }}>
                                {finshNumber(data.price!)}
                            </div>
                        </div>
                }
            </div>
            <div style={{ display: "flex" }}>
                {data.colors?.length! > 0 && data.colors?.map((color: string, index) => (
                    <Button
                        key={index}
                        onClick={() => setColorActive(color)}
                        style={{ backgroundColor: color, minWidth: 25, minHeight: 25, border: "1px solid #dddddd", margin: "10px 5px", boxShadow: colorActive === color ? `0px 0px 10px 5px ${colorActive}` : '' }}>
                    </Button>
                ))}
            </div>
            <div style={{ width: "100%", maxWidth: "1000px", display: "flex", flexDirection: "column" }}>
                <Form.Label style={{ fontWeight: 500 }}>{t("Market.If you have any comments")} :</Form.Label>
                <Form.Group className="mb-3" controlId="exampleForm.Information" style={{ display: "grid", gap: "10px" }}>
                    <Form.Control as="textarea" placeholder={t("Market.If you have any comments")} onChange={e => { }} />
                </Form.Group>
            </div>
            <div>
                <CompleteInformation data={data!} />
            </div>
            <div style={isMobile ? { padding: 10, borderBottom: "1px solid #dddddd", width: "100%", display: "flex", justifyContent: "center" } : { padding: 10, display: "flex", justifyContent: "space-around" }}>
                <div>
                    {data.disable ?
                        <Button bsPrefix="BtnBNB" onClick={() => setShowAlirtMsg(true)}>{t("Market.Remind me when available")}</Button>
                        :
                        <Button bsPrefix="BtnBNB" onClick={() => setShowAlirtMsg(true)}>{t("Market.add to cart")}</Button>
                    }
                </div>
                <div style={{ display: "flex", alignItems: "center", gap: 30 }}>
                    <div>
                        <div>
                            <Button bsPrefix="BtnBNBIconFavorite" onClick={() => { setShowAlirtMsg(true) }}><FaRegHeart size={"23px"} /></Button>
                        </div>

                    </div>
                    <div>
                        <Button bsPrefix="BtnBNBIconFavorite"><FaShareAlt size={"23px"} /></Button>
                    </div>
                </div>
            </div>
        </div>
        <div>
            <Accordion>
                <Accordion.Item eventKey="0">
                    <Accordion.Header>{t("Market.Shipping and Returns")} </Accordion.Header>
                    <Accordion.Body>
                        شبسيشبسش سيش بشسي بشسي بيس بسيب شسيب سشيب شسيب شسيب شسيب سشيب سشيب شسيبسشيبصثب سيشب شسيب سشيب سشبصثضب سيش بقشثصبلسيش  بشب صثبسيب سشيب شصثبشسيب سشيبسيبصشث بسيش
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </div>

        <Alert show={showAlirtMsg} variant={"danger"} style={{ position: "fixed", top: '90px', left: "30px", minWidth: "20%", zIndex: 5 }} onClose={() => setShowAlirtMsg(false)} dismissible>
            <Alert.Heading style={{ textAlign: "start" }}>
                {t("Market.Error message")}
            </Alert.Heading>
            <p>
                {t("Market.You are not registered, please log in to continue")}
            </p>
        </Alert>
    </>)
}

export default InfoProduct;