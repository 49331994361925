import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import './payPage.css'
import { Button } from "react-bootstrap";
import { FaRegCreditCard } from "react-icons/fa6";
import { FaMoneyBillWave } from "react-icons/fa";
import CardPayment from "./CardPayment";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { LoadingBtn } from "../../../components/laoding/laoding";
import { cleenItems, totall, WalletRedux } from "../../../redux/counterSlice";
import { useNavigate } from "react-router";
import { setUser } from "../../../redux/userSlice";
import { OthersProps } from "../../../Interfaces";


interface dataPutAddressProps {
    dataPutAddress: {
        address?: {
            country?: string, // تعديل هنا ليقبل undefined
            neighborhood?: string, // تعديل هنا ليقبل undefined
            street?: string, // تعديل هنا ليقبل undefined
            buildingNumber?: string | number, // لا تحتاج لتعديل لأنه يقبل النوعين
            floor?: number, // تعديل هنا ليقبل undefined
            apartmentNo?: number, // تعديل هنا ليقبل undefined
        }
    }
}


const PaymentMethods: React.FC<dataPutAddressProps> = ({ dataPutAddress }) => {
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const CarentItem = useSelector((state: RootState) => state.counterData)
    const userData = useSelector((state: RootState) => state.userData);
    const nav = useNavigate()

    const [paymentmethods, setPaymentMethods] = useState<string>("cash")
    const [disabled, setDisabled] = useState<boolean>(false)
    const [statusBtn, setStatusBtn] = useState<any>(t("Market.PayPage.Complete the order"))

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPaymentMethods(e.target.value);
    };

    const [dataOthers, setDataOthers] = useState<OthersProps>()

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_URL_API}/API/Others/GetOthers`)
            .then((doc) => setDataOthers(doc.data?.[0]))
            .catch((err) => console.log(err))
    }, [])

    const subTotal = totall(CarentItem)

    const delivery = 20
    const totalPrice = subTotal
    const dataItems = CarentItem?.cartItems.map((item) => {
        return {
            itemId: item.data._id,
            product: item.data.product,
            price: item.data.price,
            newPrice: item.data.newPrice,
            offerForTwoProducts: item.data.offerForTwoProducts,
            priceOfferForTwoProducts: item.data.priceOfferForTwoProducts,
            offers: item.data.offers,
            quantity: item.quantity,
            color: item.color
        }
    })

    const dataPostOrder = {
        userId: userData?.user?.userDetals?._id!,
        phone: userData?.user?.userDetals?.phone!,
        email: userData.user.userDetals?.email,
        paymentMethod: paymentmethods,
        dataItems,
        userName: userData.user.userDetals?.userName!,
        address: dataPutAddress,
        useWallet: CarentItem.useWallet,
        valueWallet: CarentItem.valueWallet,
        totalPrice,
        delivery
    }


    const BtnPostOrder = async () => {
        setDisabled(true)
        setStatusBtn(<LoadingBtn />)
        await axios.post(`${process.env.REACT_APP_URL_API}/API/Orders/PostOrder`, dataPostOrder, {
            headers: {
                Authorization: "Bearer " + userData.user.token,
            }
        })
            .then((doc) => {
                dispatch(cleenItems())
                dispatch(setUser({ userDetals: { ...userData.user.userDetals, pointsInvite: userData.user.userDetals?.pointsInvite! - CarentItem.valueWallet } }));
                dispatch(WalletRedux({ useWallet: false, valueWallet: 0 }))
                nav(`/client/market/invoice/${doc.data._id}`)
            })
            .catch(err => console.log(err))
        // createOrder(dataPostOrder)
        setStatusBtn(t("Market.PayPage.Complete the order"))
        setDisabled(false)
    }

    return (<>
        <div>
            <div style={{ marginBottom: "20px" }}>
                {t("Market.PayPage.Payment methods")} :
            </div>
            <div className="radio-container" style={{ borderBottom: "1px solid #dddddd", paddingBottom: "10px", display: "flex", gap: "40px" }}>
                <label className="radio-label" htmlFor="cash" style={{ display: "flex", gap: "5px" }}>
                    <input
                        type="radio"
                        id="cash"
                        name="paymentMethod"
                        value="cash"
                        checked={paymentmethods === "cash"}
                        onChange={handleChange}
                    />
                    <FaMoneyBillWave /> {t("Market.PayPage.cash")}
                    <span className="radio-custom"></span>
                </label>
                <label className="radio-label" htmlFor="card" style={{ display: "flex", gap: "5px" }}>
                    <input
                        type="radio"
                        id="card"
                        name="paymentMethod"
                        value="card"
                        checked={paymentmethods === "card"}
                        onChange={handleChange}
                    />
                    <FaRegCreditCard /> {t("Market.PayPage.card")}
                    <span className="radio-custom"></span>
                </label>
            </div>
            <div>
                {
                    paymentmethods === "cash" ?
                        <div style={{ width: "100%", textAlign: "center", paddingTop: "30px" }}>
                            <div>
                                <Button bsPrefix="BtnBNB" onClick={BtnPostOrder} disabled={disabled}>{statusBtn}</Button>
                            </div>
                        </div>
                        :
                        <CardPayment />
                }
            </div>
        </div>
    </>)
}

export default PaymentMethods;