import { Form } from "react-bootstrap";
import img1 from '../../files/HedarGuest/images.png'
import { useEffect } from "react";
import { useTranslation } from "react-i18next";


const I18nAdmin: React.FC = () => {
    const { i18n } = useTranslation()

    useEffect(() => {
        document.body.dir = i18n.dir()
    }, [])
    const BtnLanguge = (lan: string | undefined) => (
        i18n.changeLanguage(lan),
        window.location.reload()
    )
    return (<>
        <Form.Select style={{
            paddingLeft: "40px",
            paddingRight: "20px",
            width: "100px",
            height: "35px",
            appearance: "none",
            background: `url(${img1}) no-repeat 10px center`,
            backgroundSize: "30px 30px, 100%",
            backgroundColor: "#ffffff",
            textAlign: "center",
            cursor: "pointer"
        }} className="language-selector" onChange={(e) => BtnLanguge(e.target.value)} value={i18n.language}>
            <option value={"ar"}>AR</option>
            <option value={"en"}>EN</option>
            {/* <option value={"he"}>HE</option> */}
        </Form.Select>
    </>)
}

export default I18nAdmin;