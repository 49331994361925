import React, { useContext, useEffect, useState } from "react";
import { Product } from "../../../admin/homeAdmin/HomeAdmin";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { useNavigate } from "react-router";
import { Loading } from "../../../components/laoding/laoding";
import { ChatContext } from "../../../components/context/ChatContext";


const ClientYouMayAlsoLike: React.FC = () => {
    const { t, i18n } = useTranslation()
    const nav = useNavigate()
    const context = useContext(ChatContext)
    const { baseCurrency, currency } = context!
    const [dataProducts, setDataProducts] = useState<Product[]>()

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_URL_API}/API/Products/GetProducts`)
            .then((doc) => setDataProducts(doc.data))
            .catch(err => console.log(err))
    }, [])

    const truncateText = (text: string): string => {
        return text.length > 50 ? text.substring(0, 50) + "..." : text;
    };

    const finshNumber = (num: number) => {
        const Currency = currency === "ILS" ? "ILS" : currency === "USD" ? "USD" : "JOD"
        if (currency === "ILS") {
            const afterNum = (Number(num) * baseCurrency)
            return (afterNum.toFixed(0)) + " " + Currency
        }
        else {
            return (Number(num) * baseCurrency).toFixed(2) + " " + Currency
        }
    }

    return (<>
        <div style={{ borderBottom: "1px solid #dddddd" }}>
            {
                dataProducts ?
                    dataProducts?.sort((a, b) => new Date(b.createdAt!).getTime() - new Date(a.createdAt!).getTime()).slice(0, 6).map((Product, index) => (
                        <div
                            key={index}
                            onClick={() => nav(`/client/market/productDetails/${Product?._id}`)}
                            style={{ margin: "10px 0" }}
                        >
                            <div style={{ display: 'flex', justifyContent: "space-between", flexDirection: "row", alignItems: "center", width: "100%", cursor: "pointer", position: "relative" }}>
                                <div className="cardYouMayAlsoLike" style={{ borderRadius: "5px" }}>
                                    <img draggable={false} alt="وصف الصورة" src={`${process.env.REACT_APP_URL_API}/API/files/Products/${Product.file ? Product.file[0] : ""}`} />
                                    <img draggable={false} alt="وصف الصورة" className="imgHomeHover2" src={`${process.env.REACT_APP_URL_API}/API/files/Products/${Product.file ? Product.file[1] : ""}`} />
                                </div>
                                {
                                    Product.offers &&
                                    <div style={{ position: "absolute", top: "10px", insetInlineStart: "0px", backgroundColor: "red", borderRadius: "15px", padding: "10px", color: "white", width: "50px", height: "25px", display: "flex", justifyContent: "center", alignItems: "center", fontSize: "10px" }}>
                                        {Product.discount! * -1} %
                                    </div>
                                }
                                {
                                    Product.disable &&
                                    <div style={{ position: "absolute", top: "10px", insetInlineStart: "0px", backgroundColor: "black", borderRadius: "15px", padding: "10px", color: "white", width: "80px", height: "25px", display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center", fontSize: "10px" }}>
                                        {t("Market.Sold out")}
                                    </div>
                                }
                                <div style={{ width: "70%" }}>
                                    <div style={{ fontSize: "15px", padding: "5px", maxWidth: 250, textAlign: "end" }}>  {truncateText(
                                        (i18n.language === "ar" ? Product.product?.ar : Product.product?.en) ?? ""
                                    )}</div>
                                    <div style={{ width: "100%", fontSize: "12px" }}>
                                        {
                                            Product.newPrice ?
                                                <div style={{ display: "flex", width: "100%", justifyContent: "center", gap: '20px' }}>
                                                    <div style={{ textDecoration: "line-through", color: "gray", fontSize: 16, fontWeight: 600 }}>
                                                        {finshNumber(Product.price!)}
                                                    </div>
                                                    <div style={{ color: "red", fontSize: 16, fontWeight: 600 }}>
                                                        {finshNumber(Product.newPrice!)}
                                                    </div>
                                                </div>
                                                :
                                                <div style={{ textAlign: "center", color: "gray", fontSize: 16, fontWeight: 600 }}>
                                                    {finshNumber(Product.price!)}
                                                </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                    :
                    <Loading />
            }
        </div>
    </>)
}

export default ClientYouMayAlsoLike;