import { useEffect, useState } from "react";
import { Offers } from "../../admin/homeAdmin/HomeAdmin";
import axios from "axios";
import { motion } from "framer-motion";
import Langu from "../../components/languge/languge";

const Offer1Guest: React.FC = () => {
    const [numImg, setNumImg] = useState<number>(0)
    const [dataOffers, setDataOffers] = useState<Offers[]>()

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_URL_API}/API/Offers/GetOffers`)
            .then((doc) => setDataOffers(doc.data))
            .catch((err) => console.log(err))
    }, [])

    useEffect(() => {
        const interval = setInterval(() => {
            setNumImg(prevIndex => (prevIndex + 1) % (dataOffers?.length ?? 0))
        }, 7000);  // المدة الزمنية لكل صورة (6 ثواني)
        return () => clearInterval(interval);
    }, [dataOffers])

    return (
        <div style={{
            width: '100%',
            height: 'auto',
            minHeight: "80vh",
            maxHeight: "80vh",
            position: 'relative'
        }}>
            {dataOffers?.map((product, index) => (
                <motion.div
                    key={index}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: index === numImg ? 1 : 0 }}
                    transition={{ duration: 1 }}
                    style={{
                        backgroundImage: `url(${process.env.REACT_APP_URL_API}/API/files/Offers/${product.file ? product.file[0] : ""})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        width: '100%',
                        height: '100%',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                    }}
                >
                </motion.div>
            ))}
        </div>
    )
}

export default Offer1Guest;
