import { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import ForgotPasswordModal from "./ForgotPasswordModal";
import axios from "axios";
import SinupModal from "./SinupModal";
import { useNavigate } from "react-router";
import Cookies from "universal-cookie";
import { useDispatch } from "react-redux";
import { setUser } from "../../redux/userSlice";
import { LoadingBtn } from "../../components/laoding/laoding";
import { useTranslation } from "react-i18next";
import { cleenItems, incrementItems } from "../../redux/counterSlice";


interface LoginModalProps {
    show: boolean;
    setShowModalLogin: React.Dispatch<React.SetStateAction<boolean>>;
}


const LoginModal: React.FC<LoginModalProps> = ({ show, setShowModalLogin }) => {
    const { t } = useTranslation()
    const nav = useNavigate()
    const cookie = new Cookies()
    const dispatch = useDispatch()

    const [statusBtn, setStatusBtn] = useState<any>(t("Hedar.Login"))
    const [disabledBtn, setDisabledBtn] = useState<boolean>(false)
    const [showModalForgotPassword, setShowModalForgotPassword] = useState<boolean>(false)
    const [showModalSinup, setShowModalSinup] = useState<boolean>(false)
    const [email, setEmail] = useState<string>('')
    const [password, setPassword] = useState<string>('')
    const [errmsgLogin, setErrMsgLogin] = useState<string>('')

    const PostDataLogin = { email, password }

    const btnLogin = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | React.KeyboardEvent<HTMLInputElement>) => {
        setDisabledBtn(true)
        setStatusBtn(<LoadingBtn />)
        await axios.post(`${process.env.REACT_APP_URL_API}/API/Auth/Login`, PostDataLogin)
            .then(async (doc) => {
                const token = doc.data.token
                const refreshToken = doc.data.retoken
                const userDetals = doc.data.user
                localStorage.removeItem('cartItems');
                localStorage.setItem('cartItems', JSON.stringify(doc?.data?.user?.cart));
                dispatch(cleenItems())
                doc?.data?.user?.cart.map((item: any) => (
                    dispatch(incrementItems({ quantity: item.quantity, data: item.data, userId: userDetals._id, color: item.color }))
                ))
                dispatch(setUser({ token, refreshToken, userDetals }))
                cookie.set("bearer", { token, refreshToken, userDetals }, { path: "/", expires: new Date(Date.now() + 2 * 30 * 24 * 60 * 60 * 1000) })
                userDetals.role ? nav("/admin/homePage") : nav('/client/homePage')
            })
            .catch(err => setErrMsgLogin(err.response?.data?.errors?.[0].msg))
            .finally(() => {
                setStatusBtn(t("Hedar.Login"))
                setDisabledBtn(false)
            })
    }

    const handleKeyPressLogin = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            btnLogin(event);
        }
    };

    const BtnForgotPassword = () => {
        setShowModalLogin(false)
        setShowModalForgotPassword(true)
    }

    const BtnSinup = () => {
        setShowModalLogin(false)
        setShowModalSinup(true)
    }

    return (<>
        <Modal show={show} onHide={() => setShowModalLogin(false)}>

            <Modal.Header className="CloseBtn" closeButton>
                <Modal.Title>{t("Hedar.Login")}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label>{t("Hedar.Email address")} :</Form.Label>
                    <Form.Control onKeyDown={handleKeyPressLogin} type="email" placeholder="name@example.com" autoFocus onChange={e => setEmail(e.target.value)} />
                </Form.Group>
                <Form.Group controlId="exampleForm.ControlInput2" style={{ marginBottom: "0" }}>
                    <Form.Label>{t("Hedar.Password")} :</Form.Label>
                    <Form.Control onKeyDown={handleKeyPressLogin} type="password" placeholder="Password" onChange={e => setPassword(e.target.value)} />
                </Form.Group>
                {
                    errmsgLogin ?
                        <div style={{ color: "red", width: "100%", textAlign: "center", padding: "5px", backgroundColor: "#ffe9e9", borderRadius: "5px" }}>
                            {errmsgLogin}
                        </div>
                        :
                        null
                }
                <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", marginTop: 10 }}>
                    <button onClick={BtnForgotPassword} style={{ border: "none", backgroundColor: "white", color: "#0070ff" }}>{t("Hedar.did you forget your password?")}</button>
                    <button onClick={BtnSinup} style={{ border: "none", backgroundColor: "white", color: "#0070ff" }}>{t("Hedar.New customer? Create your account")}</button>
                </div>
            </Modal.Body>

            <Modal.Footer>
                <Button onClick={btnLogin} bsPrefix="BtnBNB" disabled={disabledBtn}>
                    {statusBtn}
                </Button>
                <Button variant="secondary" onClick={() => setShowModalLogin(false)}>
                    {t("Hedar.Close")}
                </Button>
            </Modal.Footer>
        </Modal>

        <ForgotPasswordModal show={showModalForgotPassword} setShowModalForgotPassword={setShowModalForgotPassword} />
        <SinupModal show={showModalSinup} setShowModalSinup={setShowModalSinup} />
    </>)
}

export default LoginModal;