import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { useTranslation } from "react-i18next";
import { Button, CloseButton, Form } from "react-bootstrap";
import { TbShoppingCartX } from "react-icons/tb";
import CartItemPayPage from "../../../components/cartItem/CartItemPayPage";
import { useNavigate } from "react-router";
import { ChatContext } from "../../../components/context/ChatContext";
import { useContext, useEffect, useState } from "react";
import { subTotall, totall, WalletRedux } from "../../../redux/counterSlice";
import axios from "axios";
import { UsersProps } from "../../../admin/homeAdmin/HomeAdmin";


const PayPage: React.FC = () => {
    const CarentItem = useSelector((state: RootState) => state.counterData)
    const userData = useSelector((state: RootState) => state.userData)
    const { t } = useTranslation()
    const nav = useNavigate()
    const dispatch = useDispatch()
    const chatContext = useContext(ChatContext);
    const { baseCurrency, currency } = chatContext!;
    const [checkWallet, setCheckWallet] = useState<boolean>(CarentItem.useWallet)
    const total: number = totall(CarentItem)
    const subTotal: number = subTotall(CarentItem)

    const [dataUser, setDataUser] = useState<UsersProps>()
    const [valueWallet, setValueWallet] = useState<number>()
    const [hoverCobon, setHoverCobon] = useState<boolean>(false)
    const [errMsg, setErrMsg] = useState<string>()

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_URL_API}/API/Users/GetUser/${userData.user.userDetals?._id}`,
            {
                headers: {
                    Authorization: "Bearer " + userData.user.token,
                }
            })
            .then((doc) => { setDataUser(doc.data) })
            .catch((err) => console.log(err))
    }, [userData.user.token, userData.user.userDetals?._id])

    useEffect(() => {
        setValueWallet(parseFloat((dataUser?.pointsInvite! * baseCurrency!).toFixed(2)))
    }, [dataUser, baseCurrency])

    const finshNumber = (num: number) => {
        const Currency = currency === "ILS" ? "ILS" : currency === "USD" ? "USD" : "JOD"
        if (currency === "ILS") {
            const afterNum = (Number(num) * baseCurrency)
            return (afterNum.toFixed(0)) + " " + Currency
        }
        else {
            return (Number(num) * baseCurrency).toFixed(2) + " " + Currency
        }
    }

    const finshNumber2 = (num: number) => {
        const Currency = currency === "ILS" ? "ILS" : currency === "USD" ? "USD" : "JOD";
        return (Number(num) * baseCurrency).toFixed(2) + " " + Currency;
    };

    const handleSwatchChangeWallet = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCheckWallet(e.target.checked);
    };

    useEffect(() => {
        setErrMsg('')
    }, [valueWallet, checkWallet])

    const handleUseWallet = () => {
        if (!valueWallet || parseFloat(valueWallet.toFixed(2)) > parseFloat(((dataUser?.pointsInvite! - CarentItem.valueWallet) * baseCurrency).toFixed(2))) {
            setErrMsg("err")
            return;
        }
        dispatch(WalletRedux({ useWallet: true, valueWallet: valueWallet / baseCurrency }))
    }

    return (<>
        <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", marginBottom: "50px" }}>
            <div style={{ width: "100%", borderBottom: "1px solid #dddddd", height: "10vh", backgroundColor: "gray", display: "flex", justifyContent: "center", alignItems: "center", color: "white" }}>
                <h2>
                    {t("Market.PayPage.shopping cart")}
                </h2>
            </div>
            <div style={{ maxWidth: "1440px", width: "100%" }}>
                {
                    CarentItem.cartItems.length > 0 ?
                        <>
                            <div style={{ width: "100%" }}>
                                <div style={{ display: "flex", justifyContent: "space-between", borderBottom: "2px solid black", fontSize: "20px", padding: "50px 0 10px 0", marginBottom: "20px" }}>
                                    <div style={{ width: "40%" }}>
                                        {t("Market.product")}
                                    </div>
                                    <div style={{ width: "20%", textAlign: "center" }}>
                                        {t("Market.price")}
                                    </div>
                                    <div style={{ width: "20%", textAlign: "center" }}>
                                        {t("Market.Quantity")}
                                    </div>
                                    <div style={{ width: "20%", textAlign: "end" }}>
                                        {t("Market.Total price")}
                                    </div>
                                </div>
                                <div style={{ width: "100%" }}>
                                    {CarentItem?.cartItems.map((item: any, index) => { return item ? <CartItemPayPage key={index} data={item} /> : null }
                                    )}
                                </div>
                                <div style={{ display: "flex", justifyContent: "space-between", marginTop: "5vh" }}>
                                    <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
                                        <div style={{ fontSize: "20px", fontWeight: "500" }}>
                                            <Form.Check type="switch" checked={checkWallet} onChange={handleSwatchChangeWallet} label={t("Market.PayPage.useWallet")} ></Form.Check>
                                        </div>
                                        <div>
                                            {t("Market.PayPage.available")} : {finshNumber2(dataUser?.pointsInvite! - CarentItem.valueWallet)}
                                            <Form.Control disabled={!checkWallet} type="number" value={valueWallet} placeholder={`${finshNumber2(dataUser?.pointsInvite!)}`} onChange={(e) => { setValueWallet(parseFloat(e.target.value)) }} />
                                        </div>
                                        <div style={{ textAlign: "center" }}>
                                            {
                                                errMsg &&
                                                <div style={{ color: "red", backgroundColor: "", fontSize: 20 }}>{errMsg}</div>
                                            }
                                            <Button bsPrefix="BtnBNB" disabled={!checkWallet} onClick={handleUseWallet} >{t("Market.PayPage.useWallet")}</Button>
                                        </div>
                                        {
                                            CarentItem.useWallet &&
                                            <div
                                                style={{ border: "1px solid #dddddd", padding: 5, borderRadius: 10, position: "relative" }}
                                                onMouseEnter={() => { setHoverCobon(true) }}
                                                onMouseLeave={() => { setHoverCobon(false) }}
                                            >
                                                {
                                                    hoverCobon &&
                                                    <CloseButton style={{ position: "absolute", top: -15, left: -10 }} onClick={() => { dispatch(WalletRedux({ useWallet: false, valueWallet: 0 })) }} />
                                                }
                                                {t("Market.PayPage.20 of the wallet has been used", { number: finshNumber2(CarentItem.valueWallet!) })}
                                            </div>
                                        }
                                    </div>
                                    <div style={{ display: "flex", flexDirection: "column", gap: "20px", textAlign: "center" }}>
                                        <div>
                                            {t("Market.PayPage.Subtotal")} : {finshNumber(total)}
                                        </div>
                                        <div style={{ color: "red", fontWeight: 500 }}>
                                            {t('Market.discount')} : {finshNumber(total - subTotal)}
                                        </div>
                                        <div style={{ fontSize: "20px", fontWeight: "500" }}>
                                            {t("Market.PayPage.Total")}: {finshNumber(subTotal)}
                                        </div>
                                        <div>
                                            <Button bsPrefix="BtnBNB" onClick={() => nav("/client/market/ompleteOrder")}> {t("Market.PayPage.Complete the order")}</Button>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </>
                        :
                        <>
                            <div style={{ display: "flex", flexDirection: 'column', alignItems: "center" }}>
                                <TbShoppingCartX size={"100px"} />
                                <h1>{t("Market.PayPage.Your shopping cart is empty")}</h1>
                            </div>
                        </>
                }
            </div>
        </div >
    </>)
}

export default PayPage;