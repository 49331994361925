import { Button, CloseButton } from "react-bootstrap";
import { NavLink, useNavigate } from "react-router-dom";
import './Hedar.client.css'
import { useEffect, useState } from "react";
import { IoSearch } from "react-icons/io5";
import Search from "../../guest/hedarGuest/Search";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import Cookies from "universal-cookie";
import { isExpired } from "react-jwt";
import axios from "axios";
import { setUser } from "../../redux/userSlice";
import I18nClient from "./I18n.client";
import { useTranslation } from "react-i18next";
import AccuntDropdownClient from "./AccuntDropdown.client";
import { MdOutlineShoppingCart } from "react-icons/md";
import ShoppingCart from "../../guest/hedarGuest/ShoppingCart";
import { GrFavorite } from "react-icons/gr";
import { ThemeColor } from "../../theme";
import { offerHedarRedux } from "../../redux/counterSlice";
import { Category } from "../../admin/homeAdmin/HomeAdmin";
import Langu from "../../components/languge/languge";
import SetCurrency from "../../components/setCurrency/SetCurrency";



const HedarClient: React.FC = () => {

    const dispatch = useDispatch();
    const userData = useSelector((state: RootState) => state.userData);
    const CarentItem = useSelector((state: RootState) => state.counterData)
    const { t } = useTranslation()
    const nav = useNavigate()
    const cookie = new Cookies()
    const getTokenCookie = cookie.get("bearer");

    const [dataCategotys, setDataCategorys] = useState<Category[]>()
    const [showShoppingCart, setShowShoppingCart] = useState<boolean>(false)
    const [showSearch, setShowSearch] = useState<boolean>(false)
    const [isVisible, setIsVisible] = useState<boolean>(true);
    const [close, setClose] = useState<Boolean>(isVisible)

    const handleClose = () => {
        // جعل العنصر يختفي تدريجيًا
        setIsVisible(false);
        // بعد فترة التأثير نحدث حالة Redux
        setTimeout(() => {
            setClose(false)
            dispatch(offerHedarRedux({ offerHedar: true }));
        }, 700);
    };

    let TokenReducer = userData?.user?.token
    let IsExpiredReducerToken = isExpired(TokenReducer!)
    let RefreshToken = getTokenCookie?.refreshToken

    const quantitys = CarentItem?.cartItems.reduce((total, cartitem) => {
        return total + cartitem.quantity
    }, 0)

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_URL_API}/API/Products/GetCategorys`)
            .then((doc) => setDataCategorys(doc.data))
            .catch((err) => console.log(err))
    }, [])

    useEffect(() => {
        if (IsExpiredReducerToken) {
            axios.get(`${process.env.REACT_APP_URL_API}/API/Auth/RefreshToken`, {
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + RefreshToken,
                }
            })
                .then((doc) => {
                    const token = doc.data.token;
                    const userDetals = doc.data.data
                    cookie.set("bearer", { token: token, refreshToken: RefreshToken, userDetals: userDetals }, { path: "/", expires: new Date(Date.now() + 2 * 30 * 24 * 60 * 60 * 1000) });
                    dispatch(setUser({ token: token, refreshToken: RefreshToken, userDetals: userDetals }))
                    console.log("ok refresh")
                })
                .catch((err) => {
                    console.log("err refreshPage : ", err);
                })
        }
    }, [IsExpiredReducerToken, RefreshToken, TokenReducer, dispatch, cookie])

    return (<>
        <nav>
            <div style={{ minHeight: close ? "95px" : "65px" }}>
                <div className="NavHedar" style={{ flexDirection: "column" }}>
                    {!CarentItem.offerHedar && (
                        <div
                            className="offer-banner"
                            style={{
                                backgroundColor: ThemeColor.bgColor(1),
                                color: "white",
                                display: "flex",
                                justifyContent: "space-between",
                                padding: 5,
                                minHeight: 20,
                                minWidth: "100%",
                                opacity: isVisible ? 1 : 0,
                                transition: "opacity 0.7s ease-out",
                            }}
                        >
                            <div></div>
                            <div style={{ fontSize: 16, fontWeight: 500 }}>
                                {t("Hedar.Shipping to Palestine and Jordan")}
                            </div>
                            <div>
                                <CloseButton
                                    variant="white"
                                    style={{ margin: "0 20px" }}
                                    onClick={handleClose}
                                />
                            </div>
                        </div>
                    )}
                    <div style={{ maxWidth: "1440px", width: "100%", padding: "0 40px", minHeight: 65, alignContent: "center" }}>
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <div>
                                <div className='MenuNavLink' style={{ display: "flex", gap: "20px" }}>
                                    {/* <Notification /> */}
                                    <NavLink className={"NavLinkHedar"} to="/client/homePage">{t("Home.home")}</NavLink>
                                    {dataCategotys?.slice(0, 7).map((Categoty, index) => (
                                        <NavLink className={"NavLinkHedar"} to={`/client/market/category/${Categoty.name.en}`} key={index}><Langu {...Categoty.name} /></NavLink>
                                    ))}
                                </div>
                            </div>
                            <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                                <div>
                                    <Button bsPrefix='BtnBNBIcon' style={{ display: "flex" }} onClick={() => setShowShoppingCart(true)}>
                                        <div style={{ backgroundColor: "#842cff", minWidth: "24px", borderRadius: "50%", color: "white" }}>{quantitys}</div>
                                        <MdOutlineShoppingCart className='IconHedar' size={"25px"} />
                                    </Button>
                                </div>
                                <div>
                                    <Button bsPrefix='BtnBNBIcon' style={{ display: "flex", gap: "2px" }} onClick={() => { nav(`/client/market/Favorite/${userData.user?.userDetals?._id}`) }}>
                                        <div style={{ backgroundColor: "#842cff", minWidth: "24px", borderRadius: "50%", color: "white", }}>{userData.user.userDetals?.favorite?.length}</div>
                                        <GrFavorite className='IconHedar' size={"25px"} />
                                    </Button>
                                </div>
                                <div>
                                    <AccuntDropdownClient />
                                </div>
                                <div>
                                    <Button bsPrefix='BtnBNBIcon' onClick={() => setShowSearch(true)}><IoSearch size={"25px"} /></Button>
                                </div>
                                <div>
                                    <SetCurrency />
                                </div>
                                <div>
                                    <I18nClient />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Search show={showSearch} setShowSearch={setShowSearch} />
            <ShoppingCart show={showShoppingCart} setShowShoppingCart={setShowShoppingCart} />
        </nav>
    </>)
}

export default HedarClient;