import { useContext } from "react";
import { Form } from "react-bootstrap";
import { ChatContext } from "../context/ChatContext";




const SetCurrency: React.FC = () => {
    const context = useContext(ChatContext)
    const { setCurrency } = context!
    return (<>
        <Form.Select style={{
            paddingLeft: "10px",
            paddingRight: "10px",
            width: "100%",
            height: "35px",
            appearance: "none",
            background: `#ffffff`,
            // backgroundSize: "0px 0px, 0%",
            // backgroundColor: "#ffffff",
            textAlign: "center",
            cursor: "pointer"
        }} onChange={(e) => { setCurrency(e.target.value) }}>
            <option value="ILS"> ILS</option>
            <option value="USD"> USD</option>
            <option value="JOD"> JOD</option>
        </Form.Select>
    </>)
}

export default SetCurrency;